import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Card, Modal } from "antd";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Article } from "../../../types/article";

type TProps = {
  id: string;
  loading: boolean;
  data: Article;
  delete: (article: string) => void;
  deleteSuccess: boolean;
  deleteError: any;
  deleteLoading: boolean;
};

const { confirm } = Modal;

export const ArticleCard = (props: TProps) => {
  const navigate = useNavigate();

  const openEdit = (id: any) => {
    navigate({
      pathname: `/dashboard/articles/edit`,
      search: createSearchParams({
        id: props?.data?._id,
      }).toString(),
    });
  };

  const openPreview = (id: any) => {
    navigate({
      pathname: `/dashboard/articles/preview`,
      search: createSearchParams({
        id: props?.data?._id,
      }).toString(),
    });
  };

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this article?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        props.delete(id);
      },
      onCancel() {},
    });
  }

  return (
    <div key={props.id}>
      <Card
        className=" rounded-md shadow-sm container w-80 mx-2 my-2"
        cover={
          <img
            alt="example"
            src={props?.data?.imageUrl}
            className="h-40 object-cover rounded-t-md "
          />
        }
        actions={[
          <DeleteOutlined
            key="delete"
            onClick={() => showDeleteConfirm(props.id)}
            style={{ color: "#dc2626" }}
            className="!fill-red-600 "
          />,
          <EditOutlined
            key="edit"
            className="fill-zinc-500 "
            style={{ color: "#71717a" }}
            onClick={openEdit}
          />,
          <EyeOutlined
            className="fill-zinc-500 "
            style={{ color: "#71717a" }}
            onClick={openPreview}
          />,
        ]}
      >
        <div className="flex flex-row justify-between items-start w-full">
          <p className="text-gray-900 text-lg font-medium truncate max-h-8">
            {props?.data?.title[0]?.key === "en"
              ? props?.data?.title[0]?.value
              : props?.data?.title[1]?.key === "en"
              ? props?.data?.title[1]?.value
              : props?.data?.title[2]?.value}
          </p>
        </div>
      </Card>
    </div>
  );
};
