import i18next from "i18next";
import { ReactComponent as FIcon } from "../../assets/icons/blog/fb.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/blog/heart.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/blog/link.svg";
import { ReactComponent as TIcon } from "../../assets/icons/blog/twitter.svg";
import SkeletonElement from "./SkeletonElement";

export default function ArticleContentSkeleton() {
  return (
    <section className="min-h-full w-full bg-black py-10">
      <div className="pt-40 md:pt-60 bg-black"></div>
      <div className="space-y-20 relative flex flex-col place-items-center  text-previewSecondary  container min-w-full  min-h-full w-full">
        <div className="space-y-4 w-5/6  md:w-3/6">
          <div className="text-sm flex flex-row place-items-center space-x-5">
            <SkeletonElement type="text" />

            <span>
              <SkeletonElement type="text" />
            </span>
            <span className="pa-2">
              <SkeletonElement type="text" />
            </span>
          </div>
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
          <div className="flex flex-row place-items-center space-x-5">
            <FIcon></FIcon>
            <TIcon></TIcon>
            <LinkIcon></LinkIcon>
          </div>
        </div>

        <SkeletonElement type="thumbnail" className="w-full h-96" />

        <div className="min-h-full min-w-full flex flex-col-reverse md:flex-row justify-between space-x-4 px-4 md:px-7">
          <div className="hidden md:sticky top-1/2 w-4/6 md:w-1/6 h-full md:flex flex-col place-items-center ">
            <div className=" min-w-full  h-full pl-5 flex flex-col-reverse md:flex-col items-start justify-center space-y-7">
              <div className="flex flex-col space-y-4">
                <FIcon></FIcon>
                <TIcon></TIcon>
                <LinkIcon></LinkIcon>
                <div className="flex flex-row place-items-center space-x-4">
                  <HeartIcon
                    className={`${i18next.language !== "en" && "ml-1"}`}
                  ></HeartIcon>
                  <span>12</span>
                </div>
              </div>

              {/* <div className="flex flex-col items-start justify-center space-y-3">
                  <span className="text-sm">{date}</span>
                </div> */}
              <div className="min-w-full">
                <span className="md:pt-8">
                  <SkeletonElement type="text" />
                </span>
                <div
                  className="min-w-full rounded mt-4"
                  style={{
                    height: "3px",
                    backgroundSize: "100% 100% !important",
                    backgroundImage:
                      "linear-gradient(to right,white 20%, white 50%)",
                  }}
                ></div>
              </div>
            </div>
          </div>
          {/* for mobile */}
          <div className=" relative mt-20 w-5/6 md:w-1/6 h-full md:hidden flex flex-col place-items-center ">
            <div className="min-w-full  h-full  flex flex-col-reverse md:flex-col items-start justify-center space-y-3">
              <div className="flex flex-row-reverse justify-between  min-w-full  mt-4">
                <div className="flex flex-row place-items-center space-x-5">
                  <FIcon></FIcon>
                  <TIcon></TIcon>
                  <LinkIcon></LinkIcon>
                </div>
                <div className="flex flex-row place-items-center space-x-4">
                  <HeartIcon></HeartIcon>
                  <span
                    className={`${
                      (i18next.language === "en" ||
                        i18next.language === "en-US") &&
                      "mr-3"
                    }`}
                  >
                    <SkeletonElement type="text" className="w-4" />
                  </span>
                </div>
              </div>

              {/* <div className="flex flex-col items-start justify-center space-y-3">
                  <span className="text-sm">{date}</span>
                </div> */}
              <div className="min-w-full space-y-3 ">
                <SkeletonElement type="text" className="text-sm pb-4" />
                <div
                  className="min-w-full rounded"
                  style={{
                    height: "3px",
                    backgroundSize: "100% 100% !important",
                    backgroundImage:
                      "linear-gradient(to right,white 20%, white 50%)",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="w-5/6 md:w-3/6 h-full font-body text-base flex flex-col space-y-6">
            {[...Array(30)].map((e, i) => {
              // Replace every 10 element with a type of title
              const isEveryTen = i % 10 === 0;
              return (
                <SkeletonElement
                  key={i}
                  type={isEveryTen ? "title" : "text"}
                  className={`${isEveryTen ? "my-6" : ""}`}
                />
              );
            })}
          </div>
          <div className="hidden md:block w-1/6 h-full px-5"></div>
        </div>
      </div>
    </section>
  );
}
