import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { message, Modal, Spin } from "antd";
import "antd/dist/antd.css";
import AddUser from "./AddUser";
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useEditUserMutation,
} from "../../../app/services/User";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { AddUserPayload, EditUserPayload, User } from "../../../types/user";
import { UserCard } from "./UserCard";

export const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  // const isFetchingUsers = useGetUsersQuery("").isFetching;
  const [
    editUser,
    {
      data: editUserData,
      isSuccess: userEdited,
      isLoading: isLoadingEditUser,
      isError: isErrorEditUser,
      error: editUserErrorData,
    },
  ] = useEditUserMutation();

  const {
    isFetching: isFetchingUsers,
    data: usersData,
    refetch,
  } = useGetUsersQuery({});

  const [
    addUser,
    {
      data: addUserData,
      isSuccess: userAdded,
      isLoading: isLoadingAddUser,
      isError: isErrorAddUser,
      error: addUserErrorData,
    },
  ] = useAddUserMutation();

  const [deleteUser, { isSuccess: userDeleted, isError: deleteUserDataError }] =
    useDeleteUserMutation();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (deleteUserDataError) return message.error("Failed to delete user");
    if (userDeleted) return message.success("Successfully deleted user");
    if (userAdded) return message.success("Successfully added user");
    if (userEdited) return message.success("Successfully edited user");
  }, [deleteUserDataError, userDeleted, userAdded, userEdited]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  useEffect(() => {
    setVisible(false);
  }, [userAdded]);

  useEffect(() => {
    refetch();
  }, [userAdded, userDeleted, userEdited, currentPage]);

  return (
    <div className="flex flex-col w-full">
      {!isFetchingUsers && (
        <div className=" mb-4">
          <button
            onClick={showModal}
            className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
          >
            <span className="mr-1 text-white">Add</span>
            <Add className="h-4 w-h-4 fill-white" />
          </button>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-4 ">
        {isFetchingUsers ? (
          <Spin className="loader-svg" />
        ) : (
          <>
            {usersData?.result !== undefined &&
              usersData?.result.map((card: User) => {
                return (
                  <div key={card._id}>
                    <UserCard
                      email={card.email}
                      name={card.name}
                      username={card.username}
                      avatar={card.avatar}
                      id={card._id}
                      edit={(user: EditUserPayload) => editUser(user)}
                      delete={(user: string) => deleteUser(user)}
                      editSuccess={userEdited}
                      editError={isErrorEditUser}
                      editLoading={isLoadingEditUser}
                      deleteSuccess={userDeleted}
                      deleteError={deleteUserDataError}
                    />
                  </div>
                );
              })}
          </>
        )}
      </div>

      <Pagination
        itemsPerPage={8}
        totalItems={usersData?.count ? usersData?.count : 12}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new user"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddUser
            isLoading={isLoadingAddUser}
            addUser={(user: AddUserPayload) => addUser(user)}
            isSuccess={userAdded}
            error={addUserErrorData}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </div>
  );
};
