import React, { useState } from "react";

import { Form, Input, Button, Spin, Alert, Upload, message } from "antd";
import PasswordChecklist from "react-password-checklist";
import { AddUserPayload } from "../../../types/user";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { MutationDefinition } from "@reduxjs/toolkit/dist/query";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addUser: (user: AddUserPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 5 },
  // wrapperCol: { span: 16 },
};

const AddUser = (props: TProps): JSX.Element => {
  const [passwordValid, setPasswordValid] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const token = localStorage.getItem("token");
  //create a state diabled button
  const [disabled, setDisabled] = useState(true);

  const onFinish = async (values: any) => {
    const avatar = values.avatar[0].response.url;

    const password: any = passwordValid;

    props.addUser({
      email: values.email,
      username: values.username,

      roles: "ADMIN",
      avatar: avatar,
      name: values.name,
      password: password,
    });
  };

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Email: "
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input className="w-60 rounded-full text-sm" />
        </Form.Item>
        <Form.Item
          label="Full name: "
          name="name"
          rules={[{ required: true, message: "Full name is required" }]}
        >
          <Input className="w-60 rounded-full text-sm" />
        </Form.Item>
        <Form.Item
          label="Username: "
          name="username"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input className=" w-60 rounded-full text-sm" autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Password: "
          name="password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password
            className="w-60 rounded-full text-sm"
            type="password"
            onChange={(e) => setPasswordValid(e.target.value)}
            autoComplete="off"
          />
        </Form.Item>{" "}
        <PasswordChecklist
          rules={["minLength"]}
          minLength={8}
          value={passwordValid}
          style={{ marginBottom: "1rem" }}
        />
        {/* <Form.Item
          label="Role: "
          name="roles"
          rules={[{ required: true, message: "Role is required" }]}
        >
          <Input className="w-60 rounded-full text-sm" type="text" />
        </Form.Item> */}
        <Form.Item
          rules={[{ required: true, message: "Avatar is required" }]}
          name="avatar"
          label="Avatar:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setDisabled(false);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="rounded-full">Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full text-sm border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full text-sm  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
              disabled={disabled}
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert
            message={
              props.error?.data?.errorMessage ==
              "name must match /^[a-zA-Z ]+$/ regular expression"
                ? "Full name can not have numbers"
                : props.error?.data?.errorMessage
            }
            type="error"
            showIcon
          />
        )}
        {/* {props.isSuccess && (
          <Alert message={"success"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default AddUser;
