import React, { useEffect, useState } from "react";

import { Form, Spin, Alert, Button, Upload, Input, message } from "antd";
import { AddCompanyLogoPayload } from "../../../types/companyLogo";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addLogo: (logo: AddCompanyLogoPayload) => void;
  isCanceled: () => void;
};

const AddLogo = (props: TProps): JSX.Element => {
  const token = localStorage.getItem("token");
  const [disabled, setDisabled] = useState(true);

  const onFinish = async (values: any) => {
    const companyLogo = values.companyLogo[0].response.url;
    props.addLogo({
      companyLogo: companyLogo,
      companyName: values.companyName,
    });
  };

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name: "
          name="companyName"
          rules={[{ required: true, message: "Company name is required" }]}
        >
          <Input className="w-60 rounded-full text-sm" type="text" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Company logo is required" }]}
          name="companyLogo"
          label="Company logo:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setDisabled(false);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button className="rounded-full text-sm">Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className=" mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
              disabled={disabled}
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert
            message={props.error?.data?.errorMessage}
            type="error"
            showIcon
          />
        )}
        {/* {props.isSuccess && (
          <Alert message={"Logo added successfully"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default AddLogo;
