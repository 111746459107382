import React, { useEffect, useState } from "react";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { useGetUserQuery } from "../../../app/services/User";
import { Avatar, Card, message, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import EditUser from "./EditUser";
import { EditUserPayload } from "../../../types/user";
import Meta from "antd/lib/card/Meta";

type TProps = {
  name: string;
  username: string;
  email: string;
  avatar: string;
  id: string;
  delete: (user: string) => void;
  edit: (user: EditUserPayload) => void;
  editSuccess: boolean;
  editError: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;
  editLoading: boolean;
};

export const UserCard = (props: TProps) => {
  const [visible, setVisible] = useState(false);

  const { confirm } = Modal;

  const {
    isFetching: isFetchingUser,
    data: userData,
    refetch,
  } = useGetUserQuery(`${props.id}`);

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this user?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        props.delete(id);
      },
      onCancel() {},
    });
  }

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);

  // useEffect(() => {
  //   props.deleteError && message.error("Failed to delete");
  //   if (props.deleteSuccess) return message.success("Successfuly deleted");
  // }, [props.deleteError, props.deleteSuccess]);

  return (
    <>
      <Card
        style={{ width: 300, marginTop: 16 }}
        className="rounded-md shadow-sm"
        actions={[
          <DeleteOutlined
            key="delete"
            onClick={() => showDeleteConfirm(props.id)}
            style={{ color: "#dc2626" }}
            className="!fill-red-600 "
          />,
          <EditOutlined
            key="edit"
            className="fill-zinc-500 "
            style={{ color: "#71717a" }}
            onClick={showModal}
          />,
        ]}
      >
        <Meta
          avatar={<Avatar src={props.avatar} />}
          title={props.name}
          // description={props.username}
        />
        <p className=" text-sm mt-4" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          {props.username}
        </p>
        <p className=" text-sm" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          {props.email}
        </p>
      </Card>
      {visible === true && (
        <Modal
          title="Edit user"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditUser
            isLoading={props.editLoading}
            editUser={(user: EditUserPayload) => props.edit(user)}
            isSuccess={props.editSuccess}
            error={props.editError}
            userData={userData}
            isCanceled={handleCancel}
            id={props.id}
          />
        </Modal>
      )}
      {/* <Card
        className="w-60 "
        cover={
          <img alt="example" src={props.avatar} className="h-40 object-cover" />
        }
        actions={[
          <DeleteOutlined
            key="delete"
            onClick={() => showDeleteConfirm(props.id)}
            style={{ color: "#dc2626" }}
            className="!fill-red-600 "
          />,
          <EditOutlined
            key="edit"
            className="fill-zinc-500 "
            style={{ color: "#71717a" }}
            onClick={showModal}
          />,
          
        ]}
      >
        <div className="flex flex-col justify-start items-start ">
          <span className="text-gray-900 text-lg font-medium">
            {props.username}
          </span>
          <span className="text-gray-700 text-base">{props.name}</span>
          <span className="text-gray-700 text-base text-ellipsis">{props.email}</span>
        </div>
      </Card> */}
    </>
  );
};
