import "./Skeleton.css";

type SkeletonElementProps = {
  type: "title" | "text" | "thumbnail" | "avatar";
  className?: string;
};

export default function SkeletonElement({
  type,
  className,
}: SkeletonElementProps) {
  const classes = `skeleton ${type} ${className} shimmer`;

  return <div className={classes}></div>;
}
