import React, { useState } from "react";

import {
  Form,
  Input,
  Button,
  Spin,
  Alert,
  Upload,
  message,
  Select,
  Space,
  Checkbox,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { toast } from "react-toastify";

import { useGetAllCompaniesLogosQuery } from "../../../app/services/companyLogo";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: boolean;
  addCompany: any;
  isCanceled: () => void;
};

const token = localStorage.getItem("token");

const layout = {
  labelCol: { span: 8 },
  // wrapperCol: { span: 16 },
};

const { Option } = Select;

const AddCompany = (props: TProps): JSX.Element => {
  const [companyBackground, setCompanyBackground] = useState();
  const [mobileBackground, setMobileBackground] = useState();
  const [ceoModalImg, setCeoModalImg] = useState();
  const [companiesImg, setCompaniesImg] = useState();
  const [companiesImgMobile, setCompaniesImgMobile] = useState();
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const [showOnSidebar, setShowOnSidebar] = useState<boolean>(false);
  // const [disabled, setDisabled] = useState<boolean>(true);

  const onCheckboxChange = () => {
    setShowOnSidebar(!showOnSidebar);
  };

  const {
    data: companyLogos,
    isFetching: isFetchingCompanyLogos,
    refetch: refetchCompanyLogos,
  } = useGetAllCompaniesLogosQuery("");

  const CompanyBackgroundUploadprops: UploadProps = {
    name: "files",
    action: `${process.env.REACT_APP_API_URL}/upload`,
    headers: {
      authorization: `${token}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setDisabled(false);
        // message.success(`${info.file.name} file uploaded successfully`);
        setCompanyBackground(info.file.response.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const CompanyMobileImageprops: UploadProps = {
    name: "files",
    action: `${process.env.REACT_APP_API_URL}/upload`,
    headers: {
      Authorization: `${token}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setDisabled(false);
        // message.success(`${info.file.name} file uploaded successfully`);
        setMobileBackground(info.file.response.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onFinish = async (values: any) => {
    // values.companyLogo = comp
    values.order = 1;
    values.imgUrl = companyBackground;
    values.mobileImgUrl = mobileBackground;
    values.showOnSidebar = showOnSidebar;
    values.ceoModalImg = ceoModalImg;
    values.companiesImg = companiesImg;
    values.companiesImgMobile = companiesImgMobile;
    props.addCompany(values);

    toast.success("Successfully created company", {
      position: "top-center",
      autoClose: 1500,
    });
    props.isCanceled();
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onChange = (value: string) => {};

  const onSearch = (value: string) => {};

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="title"
          label="Company Name"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="title">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="subtitle"
          label="Subtitle"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="subtitle">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="seeMoreText"
          label="See more text"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="seeMoreText">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          name="visitText"
          label="Visit text"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="visitText">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item {...restField} name={[name, "key"]}>
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "value"]}>
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item label="Company Link: " name="companyLink">
          <Input className="w-60 rounded-full px-4  " name="companyLink" />
        </Form.Item>
        <Form.Item
          label="Key (unique name): "
          name="key"
          rules={[{ required: true, message: "key is required" }]}
        >
          <Input
            placeholder="hama-group"
            className="w-60 rounded-full px-4  "
            name="key"
          />
        </Form.Item>

        <Form.Item
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
          rules={[{ required: true, message: "Please Enter" }]}
          name="modalTitle"
          label="Modal Title"
        >
          <Form.List name="modalTitle">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                >cccc</Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
          rules={[{ required: true, message: "Please Enter" }]}
          name="modalDescription"
          label="Modal Description"
        >
          <Form.List name="modalDescription">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="center"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full px-4"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <TextArea
                        className="border-2 w-60 border-slate-300 shadow-sm rounded-full px-4"
                        // placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          label="Company Logo: "
          name="companyLogo"
          rules={[{ required: true, message: "company logo is required" }]}
        >
          <Select
            showSearch
            placeholder="Select a Company Logo"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            className="rounded-full"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {companyLogos?.result?.map((logo: any) => (
              <Option value={logo._id}>{logo.companyName}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Background Image: "
          name="imgUrl"
          rules={[{ required: true, message: "Image is required" }]}
        >
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setDisabled(false);
                setCompanyBackground(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Mobile Image: "
          name="mobileImgUrl"
          rules={[{ required: true, message: "Image is required" }]}
        >
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setDisabled(false);
                setMobileBackground(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="Ceo modal image:" name="ceoModalImg">
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setDisabled(false);
                setCeoModalImg(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="companies image: " name="companiesImg">
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setDisabled(false);
                setCompaniesImg(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item label="companies image mobile: " name="companiesImgMobile">
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setDisabled(false);
                setCompaniesImgMobile(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Show on sidebar" valuePropName="showOnSidebar">
          <Checkbox checked={showOnSidebar} onChange={onCheckboxChange} />
        </Form.Item>

        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full px-4 border  py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full   items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
              disabled={disabled}
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert message={"Failed Editing the company"} type="error" showIcon />
        )}
        {/* {props.isSuccess && (
          <Alert message={"success"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default AddCompany;
