import React, { useEffect, useState } from "react";

import { Form, Input, Button, Spin, Alert, Upload, message } from "antd";
import PasswordChecklist from "react-password-checklist";
import { AddUserPayload, EditUserPayload, User } from "../../../types/user";
import { useGetUserQuery } from "../../../app/services/User";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  editUser: (user: EditUserPayload) => void;
  isCanceled: () => void;
  id: string;
  userData?: User;
};

const layout = {
  labelCol: { span: 5 },
  // wrapperCol: { span: 16 },
};

const EditUser = (props: TProps): JSX.Element => {
  const [passwordValid, setPasswordValid] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const token = localStorage.getItem("token");
  const [initialValues, setInitialValues] = useState<any>();
  const [image, setImage] = useState("");
  const [previewsImage, setPreviewImage] = useState<{
    url?: string;
    uid?: string;
  }>();

  const {
    isFetching: isFetchingUser,
    data: userData,
    refetch,
  } = useGetUserQuery(`${props.id}`);

  const onFinish = async (values: any) => {
    let avatar = values?.avatar[0]?.response?.url;
    if (!avatar) avatar = previewsImage?.url;

    props.editUser({
      email: values?.email,
      username: values?.username,
      roles: "ADMIN",
      avatar: avatar,
      name: values?.name,
      password: userData?.password,
      _id: props.id,
    });
  };

  useEffect(() => {
    if (props.userData) {
      setInitialValues({
        email: userData?.email,
        name: userData?.name,
        username: userData?.username,
        password: userData?.password,

        avatar: [
          { url: userData?.avatar, uid: "23234fdf", name: userData?.avatar },
        ],
      });

      setPreviewImage({
        url: props.userData.avatar,
      });
    }
  }, [userData, isFetchingUser, props.userData]);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Email: "
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input
            className="w-60 rounded-full"
            defaultValue={props.userData?.email}
          />
        </Form.Item>
        <Form.Item
          label="Full name: "
          name="name"
          rules={[{ required: true, message: "Full name is required" }]}
        >
          <Input
            className="w-60 rounded-full"
            defaultValue={props.userData?.name}
          />
        </Form.Item>
        <Form.Item
          label="Username: "
          name="username"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input
            className=" w-60 rounded-full"
            defaultValue={props.userData?.username}
          />
        </Form.Item>
        {/* <Form.Item
          label="Password: "
          name="password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password
            className="w-60 rounded-full"
            type="password"
            onChange={(e) => setPasswordValid(e.target.value)}
            defaultValue={props.userData?.password}
          />
        </Form.Item>{" "}
        <PasswordChecklist
          rules={["minLength"]}
          minLength={8}
          value={passwordValid}
          style={{ marginBottom: "1rem" }}
        /> */}
        <Form.Item
          rules={[{ required: true, message: "Avatar is required" }]}
          name="avatar"
          label="Avatar:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
          // initialValue={userData?.avatar}
        >
          <Upload
            name="files"
            className="rounded-full"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            defaultFileList={
              props.userData?.avatar
                ? [
                    {
                      url: props.userData?.avatar,
                      uid: props.userData?._id,
                      name: props.userData?.avatar.substring(90),
                    },
                  ]
                : undefined
            }
          >
            <Button className="w-60 rounded-full">Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert message={props.error.data.message} type="error" showIcon />
        )}
        {/* {props.isSuccess && (
          <Alert message={"success"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default EditUser;
