import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message, Space, Upload } from "antd";
import {
  useGetSettingsQuery,
  useEditSettingsMutation,
} from "../../../app/services/settings";
import TextArea from "antd/lib/input/TextArea";

export const Settings = () => {
  const { data: settingsData, isFetching: isFetchingSettings } =
    useGetSettingsQuery("");

  const [initialValues, setInitialValues] = useState<any>();
  const [showFb, setShowFb] = useState<boolean>(false);
  const [showIg, setShowIg] = useState<boolean>(false);
  const [showTwitter, setShowTwitter] = useState<boolean>(false);
  const [showYt, setShowYt] = useState<boolean>(false);
  const [showLinkedIn, setShowLinkedIn] = useState<boolean>(false);

  const onShowFbChange = () => {
    setShowFb(!showFb);
  };
  const onShowIgChange = () => {
    setShowIg(!showIg);
  };
  const onShowTwitterChange = () => {
    setShowTwitter(!showTwitter);
  };
  const onShowYtChange = () => {
    setShowYt(!showYt);
  };
  const onShowLinkedInChange = () => {
    setShowLinkedIn(!showLinkedIn);
  };

  const initialArr = [
    { key: "en", value: "" },
    { key: "ar", value: "" },
    { key: "ku", value: "" },
  ];

  useEffect(() => {
    console.log(settingsData?.result);
    setInitialValues({
      ...settingsData?.result[0],

      // we have to put the phoneNumber default values on this form
      phoneNumber: {
        phoneNumber: settingsData?.result[0].phoneNumber?.phoneNumber ?? "",
        flag: [
          {
            url: settingsData?.result[0].phoneNumber?.flag ?? "",
            uid: "243vxdgf",
            name: settingsData?.result[0].phoneNumber?.flag ?? "",
          },
        ],
      },
      phoneNumber2: {
        phoneNumber: settingsData?.result[0].phoneNumber2?.phoneNumber ?? "",
        flag: [
          {
            url: settingsData?.result[0].phoneNumber2?.flag ?? "",
            uid: "2445xdgf",
            name: settingsData?.result[0].phoneNumber2?.flag ?? "",
          },
        ],
      },
      phoneNumber3: {
        phoneNumber: settingsData?.result[0].phoneNumber3?.phoneNumber ?? "",
        flag: [
          {
            url: settingsData?.result[0].phoneNumber3?.flag ?? "",
            uid: "2223vxdgf",
            name: settingsData?.result[0].phoneNumber3?.flag ?? "",
          },
        ],
      },
      phoneNumber4: {
        phoneNumber: settingsData?.result[0].phoneNumber4?.phoneNumber ?? "",
        flag: [
          {
            url: settingsData?.result[0].phoneNumber4?.flag ?? "",
            uid: "183vxdgf",
            name: settingsData?.result[0].phoneNumber4?.flag ?? "",
          },
        ],
      },
      phoneNumber5: {
        phoneNumber: settingsData?.result[0].phoneNumber5?.phoneNumber ?? "",
        flag: [
          {
            url: settingsData?.result[0].phoneNumber5?.flag ?? "",
            uid: "678vxdgf",
            name: settingsData?.result[0].phoneNumber5?.flag ?? "",
          },
        ],
      },
    });

    setShowLinkedIn(settingsData?.result[0].showLinkedIn);
    setShowFb(settingsData?.result[0].showFb);
    setShowIg(settingsData?.result[0].showIg);
    setShowTwitter(settingsData?.result[0].showTwitter);
    setShowYt(settingsData?.result[0].showYt);
  }, [settingsData, isFetchingSettings]);

  // edit
  const [
    editSettings,
    {
      isLoading: isEditingSettings,
      isError: isError,
      error: isErrorSettingsData,
      isSuccess: settingsEdited,
    },
  ] = useEditSettingsMutation();

  const onFinishFailed = (errorInfo: any) => {};

  const [form] = Form.useForm();

  useEffect(() => {
    // form.resetFields();

    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const onFinish = (values: any) => {
    console.log(values);

    values.address.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.secondAddress.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.thirdAddress.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.copyRightText.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.cooporationText.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.cooporateNewsText.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.cooporateDesc.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.menuButtonText.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.updatesButtonText.forEach((a: { _id: any }) => {
      delete a._id;
    });

    values.sidebarBlog.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.scrollToExploreText.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.getInTouch.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.getInTouchDesc.forEach((a: { _id: any }) => {
      delete a._id;
    });
    console.log(values);

    values.phoneNumber.flag =
      values.phoneNumber?.flag[0]?.response?.url ??
      initialValues.phoneNumber?.flag[0]?.url;
    values.phoneNumber2.flag =
      values.phoneNumber2?.flag[0]?.response?.url ??
      initialValues.phoneNumber2?.flag[0]?.url;
    values.phoneNumber3.flag =
      values.phoneNumber3?.flag[0]?.response?.url ??
      initialValues.phoneNumber3?.flag[0]?.url;
    values.phoneNumber4.flag =
      values.phoneNumber4?.flag[0]?.response?.url ??
      initialValues.phoneNumber4?.flag[0]?.url;
    values.phoneNumber5.flag =
      values.phoneNumber5?.flag[0]?.response?.url ??
      initialValues.phoneNumber5?.flag[0]?.url;

    // editSettings(values);
    editSettings({
      ...values,
      showFb,
      showIg,
      showTwitter,
      showYt,
      showLinkedIn,
    });
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  useEffect(() => {
    if (isErrorSettingsData) return message.error("Failed to edit settings");
    if (settingsEdited) return message.success("Successfully edited settings");
  }, [isErrorSettingsData, settingsEdited]);

  console.log(initialValues);

  const normFile = (e: any) => {
    console.log(e);
    return e && e.fileList;
  };

  const token = localStorage.getItem("token");

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6 ">
          <div className="mt-5 md:col-span-full md:mt-0 ">
            <Form
              name="settings"
              initialValues={{ remember: false }}
              onFinish={onFinish}
              form={form}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              {...layout}
            >
              <div className="shadow sm:overflow-hidden bg-white text-sm py-2 px-4">
                <div className="space-y-6 bg-white px-4  sm:p-6">
                  <Form.Item
                    name="twitterUrl"
                    label="Twitter Url"
                    className="mt-10"
                    rules={[{ required: true, message: "Please Enter" }]}
                  >
                    <Input
                      type="text"
                      id="company-website"
                      className="x border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://twitter.com/username"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Youtube Url"
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="youtubeUrl"
                  >
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://youtube.com/username"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Linkedin Url"
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="linkedinUrl"
                  >
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://linkedin.com/username"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Instagram Url"
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="instagramUrl"
                  >
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://instagram.com/username"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="facebookUrl"
                    label="Facebook URL"
                  >
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://facebook.com/username"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Messenger Url"
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="messengerUrl"
                  >
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://facebook.com/username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: "Please Enter" }]}
                  >
                    <Input
                      type="email"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="email@mail.com"
                    />
                  </Form.Item>

                  <Form.Item
                    name={["phoneNumber", "phoneNumber"]}
                    label="Phone Number"
                  >
                    <Input
                      type="phone"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm   rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="+964 750 000 0000"
                    />
                  </Form.Item>

                  <Form.Item
                    name={["phoneNumber", "flag"]}
                    label="Flag"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    // initialValue={userData?.avatar}
                  >
                    <Upload
                      name="files"
                      action={`${process.env.REACT_APP_API_URL}/upload`}
                      headers={{ Authorization: `Bearer ${token}` }}
                      multiple={false}
                      listType="picture"
                    >
                      <Button className="rounded-2xl text-sm">
                        Click to upload
                      </Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    name={["phoneNumber2", "phoneNumber"]}
                    label="Second Number"
                  >
                    <Input
                      type="phone"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm   rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="+964 750 000 0000"
                    />
                  </Form.Item>
                  <Form.Item
                    name={["phoneNumber2", "flag"]}
                    label="Flag"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    // initialValue={userData?.avatar}
                  >
                    <Upload
                      name="files"
                      action={`${process.env.REACT_APP_API_URL}/upload`}
                      headers={{ Authorization: `Bearer ${token}` }}
                      listType="picture"
                    >
                      <Button className="rounded-2xl text-sm">
                        Click to upload
                      </Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    name={["phoneNumber3", "phoneNumber"]}
                    label="Third Number"
                  >
                    <Input
                      type="phone"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm   rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="+964 750 000 0000"
                    />
                  </Form.Item>
                  <Form.Item
                    name={["phoneNumber3", "flag"]}
                    label="Flag"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    // initialValue={userData?.avatar}
                  >
                    <Upload
                      name="files"
                      action={`${process.env.REACT_APP_API_URL}/upload`}
                      headers={{ Authorization: `Bearer ${token}` }}
                      listType="picture"
                    >
                      <Button className="rounded-2xl text-sm">
                        Click to upload
                      </Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    name={["phoneNumber4", "phoneNumber"]}
                    label="Forth Number"
                  >
                    <Input
                      type="phone"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm   rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="+964 750 000 0000"
                    />
                  </Form.Item>
                  <Form.Item
                    name={["phoneNumber4", "flag"]}
                    label="Flag"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    // initialValue={userData?.avatar}
                  >
                    <Upload
                      name="files"
                      action={`${process.env.REACT_APP_API_URL}/upload`}
                      headers={{ Authorization: `Bearer ${token}` }}
                      listType="picture"
                    >
                      <Button className="rounded-2xl text-sm">
                        Click to upload
                      </Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    name={["phoneNumber5", "phoneNumber"]}
                    label="Fifth Number"
                  >
                    <Input
                      type="phone"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm   rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="+964 750 000 0000"
                    />
                  </Form.Item>
                  <Form.Item
                    name={["phoneNumber5", "flag"]}
                    label="Flag"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    // initialValue={userData?.avatar}
                  >
                    <Upload
                      name="files"
                      action={`${process.env.REACT_APP_API_URL}/upload`}
                      headers={{ Authorization: `Bearer ${token}` }}
                      listType="picture"
                    >
                      <Button className="rounded-2xl text-sm">
                        Click to upload
                      </Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item name="address" label="Address">
                    <Form.List
                      name="address"
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item {...restField} name={[name, "value"]}>
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item name="secondAddress" label="Second Address">
                    <Form.List name="secondAddress">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item {...restField} name={[name, "value"]}>
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item name="thirdAddress" label="Third Address">
                    <Form.List name="thirdAddress">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item {...restField} name={[name, "value"]}>
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item label="Address 1 url" name="addressLink">
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://facebook.com/username"
                    />
                  </Form.Item>
                  <Form.Item label="Address 2 url" name="addressLink2">
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://facebook.com/username"
                    />
                  </Form.Item>
                  <Form.Item label="Address 3 url" name="addressLink3">
                    <Input
                      type="text"
                      id="company-website"
                      className="border-2 border-slate-300 shadow-sm  w-full flex-1  rounded-full text-sm py-2 px-4 sm:text-sm"
                      placeholder="http://facebook.com/username"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="copyRightText"
                    label="Copyright text"
                  >
                    <Form.List
                      name="copyRightText"
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="cooporateNewsText"
                    label="Cooporate news"
                  >
                    <Form.List
                      name="cooporateNewsText"
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="cooporationText"
                    label="Cooporation"
                  >
                    <Form.List
                      name="cooporationText"
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="cooporateDesc"
                    label="Cooporate Description"
                  >
                    <Form.List
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                      name="cooporateDesc"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <TextArea
                                  className="border-2 w-60 border-slate-300 shadow-sm rounded-full"
                                  // placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="menuButtonText"
                    label="Menu button text"
                  >
                    <Form.List
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                      name="menuButtonText"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="updatesButtonText"
                    label="Updates button text"
                  >
                    <Form.List
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                      name="updatesButtonText"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="sidebarBlog"
                    label="Sidebar blog text"
                  >
                    <Form.List
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                      name="sidebarBlog"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="scrollToExploreText"
                    label="Scroll to explore text"
                  >
                    <Form.List
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                      name="scrollToExploreText"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="getInTouch"
                    label="Get In Touch Text"
                  >
                    <Form.List
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                      name="getInTouch"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Please Enter" }]}
                    name="getInTouchDesc"
                    label="Get In Touch Description"
                  >
                    <Form.List
                      initialValue={[
                        { key: "en", value: "" },
                        {
                          key: "ar",
                          value: "",
                        },
                        {
                          key: "ku",
                          value: "",
                        },
                      ]}
                      name="getInTouchDesc"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                alignItems: "center",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                              >
                                <Input
                                  className="border-2 border-slate-300 shadow-s rounded-full text-sm py-2 px-4"
                                  placeholder="Key"
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Value",
                                  },
                                ]}
                              >
                                <TextArea
                                  className="border-2 w-60 border-slate-300 shadow-sm rounded-full"
                                  // placeholder="Value"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                            </Space>
                          ))}
                          <Form.Item
                            rules={[
                              { required: true, message: "Please Enter" },
                            ]}
                          ></Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>

                  <Form.Item
                    label="Show Facebook"
                    valuePropName="showFb"
                    name="showFb"
                  >
                    <Checkbox checked={showFb} onChange={onShowFbChange} />
                  </Form.Item>
                  <Form.Item
                    label="Show Instagram"
                    valuePropName="showIg"
                    name="showIg"
                  >
                    <Checkbox checked={showIg} onChange={onShowIgChange} />
                  </Form.Item>
                  <Form.Item
                    label="Show Twitter"
                    valuePropName="showTwitter"
                    name="showTwitter"
                  >
                    <Checkbox
                      checked={showTwitter}
                      onChange={onShowTwitterChange}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Show YouTube"
                    valuePropName="showYt"
                    name="showYt"
                  >
                    <Checkbox checked={showYt} onChange={onShowYtChange} />
                  </Form.Item>
                  <Form.Item
                    label="Show LinkedIn"
                    valuePropName="showLinkedIn"
                    name="showLinkedIn"
                  >
                    <Checkbox
                      checked={showLinkedIn}
                      onChange={onShowLinkedInChange}
                    />
                  </Form.Item>

                  <Form.Item name="_id" style={{ display: "none" }}>
                    <Input type="hidden" name="id" value={initialValues?._id} />
                  </Form.Item>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="inline-flex justify-center rounded-full border border-transparent bg-primary  text-sm font-medium text-secondary shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2  focus:ring-offset-2"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
