import { Form, Input, Button, Row, Col, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useRequestResetMutation } from "../../app/services/User";
import { useEffect } from "react";
import { toast } from "react-toastify";

const { Title } = Typography;

type TValues = {
  email: string;
};
const ForgotPassword = (): JSX.Element => {
  const [requestReset, result] = useRequestResetMutation();
  const onFinish = (values: TValues) => {
    console.log(values);
    requestReset(values);
  };

  useEffect(() => {
    result.isSuccess &&
      toast.success("Email Sent.", {
        position: "top-center",
        autoClose: 1500,
      });
    if (result.isError) {
      const msg: any = result.error;
      console.log(msg);
      toast.error(msg?.data?.errorMessage?.toString(), {
        position: "top-center",
        autoClose: 1500,
      });
    }
  }, [result]);

  return (
    <Row
      style={{ height: "100vh", overflow: "hidden" }}
      justify={"center"}
      align={"middle"}
      className="px-4 md:px-0 "
    >
      <Col md={9} xl={6}>
        <Row justify={"center"} style={{ margin: "2rem 0rem" }}>
          <Title level={3}>Forgot your password ? </Title>
          <p>enter your email address to reset password</p>
        </Row>

        <Form
          layout={"vertical"}
          size={"large"}
          name="normal_login"
          className="login-form flex flex-col items-center justify-center"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
                type: "email",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
              className="rounded-full px-5 w-72"
            />
          </Form.Item>
          <Form.Item>
            <Button
              block
              style={{ borderRadius: ".7rem" }}
              type="primary"
              htmlType="submit"
              className="w-72 login-form-button bg-secondary text-white hover:bg-primary hover:text-secondary border-none rounded-full"
            >
              send
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
