import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../basequery";

export const baseApi = createApi({
  baseQuery,
  endpoints: (builder) => ({}),
  refetchOnMountOrArgChange:true,
  refetchOnFocus:true,
  refetchOnReconnect:true,
});
