import i18n from "./i18n";
import { Language } from "./types/article";

import { lang as langFromStorage } from ".";
import { NormalComponents } from "react-markdown/lib/complex-types";
import { SpecialComponents } from "react-markdown/lib/ast-to-react";

export function getValueFromLang(names: any): string {
  // If the "lang" key exists in the localStorage, use that.
  if (names?.length > 0) {
    if (langFromStorage !== undefined) {
      if (names && typeof names[0] !== "string") {
        return names.find((name: Language) => name.key === langFromStorage)
          .value;
      }
    }
    // If the "lang" key doesn't exist in the localStorage then make English the default
    else if (names && typeof names[0] !== "string") {
      return names.find((name: Language) => name.key === "en").value;
    }
  }

  if (i18n.language === "en") {
    return "Invalid data from API.";
  } else if (i18n.language === "ku") return "داتای هەڵە هەیە لە سێرڤەرەکەدا.";
  else {
    return "بيانات غير صالحة من خادم.";
  }
}



export function getDayMonthYear(date: string) {
  const month = date.slice(5, 7);
  const year = date.slice(0, 4);
  const day = date.slice(8, 10);
  return { day, month, year };
}

export const getShareLinks = (url: string) => {
  return {
    facebook: `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&display=popup&href=${url}`,
    twitter: `https://twitter.com/intent/tweet?url=${url}`,
    link: url,
  };
};
