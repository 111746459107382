import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Card, message, Modal, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useGetTagQuery } from "../../../app/services/tags";
import { EditTagPayload, Tag } from "../../../types/tags";
import EditTag from "./EditTag";

type TProps = {
  id: string;
  loading: boolean;
  data: Tag;
  delete: (tag: string) => void;
  edit: (tag: EditTagPayload) => void;
  editSuccess: boolean;
  editError: any;
  deleteSuccess: boolean;
  deleteError: any;
  editLoading: boolean;
  deleteLoading: boolean;
};

const { confirm } = Modal;

export const TagsCard = (props: TProps) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(props.loading);

  // const {
  //   isFetching: isFetchingTag,
  //   data: tagData,
  //   refetch,
  // } = useGetTagQuery(`${props.id}`);

  const onChange = (checked: boolean) => {
    setLoading(!checked);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this tag?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        props.delete(id);
      },
      onCancel() {},
    });
  }

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);

  return (
    <div key={props.id}>
      <Card
        id="tagscard"
        className="w-60 my-2 mt-4   shadow-sm "
        actions={[
          <DeleteOutlined
            key="delete"
            onClick={() => showDeleteConfirm(props.id)}
            style={{ color: "#dc2626" }}
            className="!fill-red-600 "
          />,
          <EditOutlined
            key="edit"
            className="fill-zinc-500 "
            style={{ color: "#71717a" }}
            onClick={showModal}
          />,
        ]}
      >
        <Skeleton loading={loading} avatar active>
          {props.data.tagName.map(
            (tag: { key: string; value: string }, index: number) => {
              return (
                <div
                  className={
                    ` flex flex-row justify-between items-center px-10 w-full h-16 ${
                      index === 1 && ""
                    } ` + (index % 2 === 1 ? "bg-gray-100" : "bg-white")
                  }
                >
                  <p className="font-bold">{tag.key}</p>
                  <p>:</p>
                  <p className="w-2/3">{tag.value}</p>
                </div>
              );
            }
          )}
        </Skeleton>
      </Card>

      {visible === true && (
        <Modal
          title="Edit tag"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditTag
            isLoading={props.editLoading}
            editTag={(tag: EditTagPayload) => props.edit(tag)}
            isSuccess={props.editSuccess}
            error={props.editError}
            // tagData={tagData}
            isCanceled={handleCancel}
            id={props.id}
          />
        </Modal>
      )}
    </div>
  );
};
