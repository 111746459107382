import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils/utils";
import logo from "../assets/images/logo-placeholder.png";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { useDispatch } from "react-redux";

type TProps = {
  name: string;
  styles: string;
  setIsSelected?: (name: string) => void;
  svg: JSX.Element;
  isCollapsed: boolean;
  onClick?: () => void;
};

const NavItem = ({
  name,
  styles,
  setIsSelected,
  svg,
  isCollapsed,
  onClick,
}: TProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      className={`font-normal text-lg flex cursor-pointer place-items-center gap-1 w-full px-3 py-2 rounded-full ${styles}`}
      onClick={() => {
        setIsSelected !== undefined && setIsSelected(name);
        navigate(`/${name === "dashboard" ? name : "dashboard/" + name}`);
      }}
    >
      <div className="flex flex-row items-center pt-1  w-full  place-items-center gap-x-5 ">
        <div className="h-full  flex place-items-center">{svg}</div>
        <div className=" pb-1 text-base ">
          {!isCollapsed && capitalizeFirstLetter(name)}
        </div>
      </div>
      {/* <div className={`${isCollapsed ? "opacity-0" : "opacity-100"}`}>
        {capitalizeFirstLetter(name)}
      </div> */}
      {/* {!isCollapsed && <div></div>} */}
    </div>
  );
};

const SideBar = ({ currentRoute }: { currentRoute: string }) => {
  const [isSelected, setIsSelected] = useState(
    currentRoute === "dashboard" ? "dashboard" : currentRoute.split("/")[1]
  );
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();

  if (isSelected === "logout") {
    localStorage.removeItem("token");
    navigate("/login");
  }
 

  return (
    <div
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
      className={`bg-secondary transition-all h-full min-h-screen    ${
        isCollapsed ? "w-20" : "sm:w-6/12 lg:w-64"
      }  text-lightGray `}
    >
      <div className={"flex justify-center items-center p-3"}>
        {/* <img src={logo} alt="logo" className="w-10/12  mt-5" /> */}
        <Logo className="w-auto  mt-5" />
      </div>
      <div className="flex flex-col space-y-7 items-start  justify-start w-full px-4 pl-2 min-h-full mt-14">
        <NavItem
          isCollapsed={isCollapsed}
          name="user"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "user"
              ? "bg-primary text-black font-semibold "
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              stroke=""
              fill={
                !isCollapsed && isSelected === "user"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "user"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={!isCollapsed && isSelected === "user" ? 0.9 : 0.1}
            >
              <path d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z" />
            </svg>
          }
        />

        <NavItem
          isCollapsed={isCollapsed}
          name="company"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "company"
              ? "bg-primary text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8 " : "h-8 w-8  ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              fill={
                !isCollapsed && isSelected === "company"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "company"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={
                !isCollapsed && isSelected === "company  " ? 0.9 : 0.1
              }
            >
              <path d="m7 14a1 1 0 0 1 -1 1h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 1 1zm4-1h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm-5 4h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm5 0h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm-5-12h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm5 0h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm-5 4h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm5 0h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2zm13 1v9a5.006 5.006 0 0 1 -5 5h-14a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h6a5.006 5.006 0 0 1 5 5h3a5.006 5.006 0 0 1 5 5zm-19 12h9v-17a3 3 0 0 0 -3-3h-6a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3zm17-12a3 3 0 0 0 -3-3h-3v15h3a3 3 0 0 0 3-3zm-3 3a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm0 4a1 1 0 1 0 1 1 1 1 0 0 0 -1-1zm0-8a1 1 0 1 0 1 1 1 1 0 0 0 -1-1z" />
            </svg>
          }
        />

        <NavItem
          isCollapsed={isCollapsed}
          name="tags"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "tags"
              ? "bg-primary text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8" : "h-8 w-8 ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              fill={
                !isCollapsed && isSelected === "tags"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "tags"
                  ? "yellow"
                  : "white"
              }
              strokeWidth={!isCollapsed && isSelected === "tags" ? 0.9 : 0.1}
            >
              <path d="M7.707,9.256c.391,.391,.391,1.024,0,1.414-.391,.391-1.024,.391-1.414,0-.391-.391-.391-1.024,0-1.414,.391-.391,1.024-.391,1.414,0Zm13.852,6.085l-.565,.565c-.027,1.233-.505,2.457-1.435,3.399l-3.167,3.208c-.943,.955-2.201,1.483-3.543,1.487h-.017c-1.335,0-2.59-.52-3.534-1.464L1.882,15.183c-.65-.649-.964-1.542-.864-2.453l.765-6.916c.051-.456,.404-.819,.858-.881l6.889-.942c.932-.124,1.87,.193,2.528,.851l7.475,7.412c.387,.387,.697,.823,.931,1.288,.812-1.166,.698-2.795-.342-3.835L12.531,2.302c-.229-.229-.545-.335-.851-.292l-6.889,.942c-.549,.074-1.052-.309-1.127-.855-.074-.547,.309-1.051,.855-1.126L11.409,.028c.921-.131,1.869,.191,2.528,.852l7.589,7.405c1.946,1.945,1.957,5.107,.032,7.057Zm-3.438-1.67l-7.475-7.412c-.223-.223-.536-.326-.847-.287l-6.115,.837-.679,6.14c-.033,.303,.071,.601,.287,.816l7.416,7.353c.569,.57,1.322,.881,2.123,.881h.01c.806-.002,1.561-.319,2.126-.893l3.167-3.208c1.155-1.17,1.149-3.067-.014-4.229Z" />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="articles"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "articles"
              ? "bg-primary text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8" : "h-8 w-8 ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              strokeWidth={
                !isCollapsed && isSelected === "articles" ? 0.9 : 0.1
              }
              fill={
                !isCollapsed && isSelected === "articles"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "articles"
                  ? "yellow"
                  : "white"
              }
            >
              <path d="m17 14a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
            </svg>
          }
        />
        <NavItem
          isCollapsed={isCollapsed}
          name="company-logo"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "company-logo"
              ? "bg-primary text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8" : "h-8 w-8 ml-2"
              } transition-all ease-in-out `}
              viewBox="0 0 30 30"
              strokeWidth={
                !isCollapsed && isSelected === "company-logo" ? 0.9 : 0.1
              }
              fill={
                !isCollapsed && isSelected === "company-logo"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "company-logo"
                  ? "yellow"
                  : "white"
              }
            >
              <path d="m20 4h-5a4 4 0 0 0 -4-4h-7a4 4 0 0 0 -4 4v19a1 1 0 0 0 2 0v-10h8a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4v-5a4 4 0 0 0 -4-4zm-18 7v-7a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v5a2 2 0 0 1 -2 2zm20 2a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2-2v-.142a4 4 0 0 0 3-3.858v-3h5a2 2 0 0 1 2 2z" />
            </svg>
          }
        />

        <NavItem
          isCollapsed={isCollapsed}
          name="settings"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "settings"
              ? "bg-primary text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8" : "h-8 w-8 ml-2"
              } transition-all ease-in-out `}
              fill={
                !isCollapsed && isSelected === "settings"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "settings"
                  ? "yellow"
                  : "white"
              }
              viewBox="0 0 30 30"
              strokeWidth={
                !isCollapsed && isSelected === "settings" ? 0.9 : 0.1
              }
            >
              <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
              <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
            </svg>
          }
        />

        <NavItem
          isCollapsed={isCollapsed}
          name="logout"
          setIsSelected={(name: string) => setIsSelected(name)}
          styles={
            !isCollapsed && isSelected === "logout"
              ? "bg-primary text-black font-semibold"
              : ""
          }
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isCollapsed ? "h-8 w-8" : "h-8 w-8 ml-2"
              } transition-all ease-in-out `}
              fill={
                !isCollapsed && isSelected === "logout"
                  ? "black"
                  : "white" && isCollapsed && isSelected === "logout"
                  ? "yellow"
                  : "white"
              }
              viewBox="0 0 30 30"
              strokeWidth={!isCollapsed && isSelected === "logout" ? 0.9 : 0.1}
            >
              <path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z" />
            </svg>
          }
        />
      </div>
    </div>
  );
};

export default SideBar;
