import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, Spin, Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../app/services/auth";
import { Spinner } from "../../components/UI/Spinner";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
const { Title } = Typography;

type FormData = {
  username: string;
  password: string;
};

export default function Login() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [login, loginResult] = useLoginMutation();
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    login(data).then(({ data }: any) => {
      if (data !== undefined) {
        const token = data?.token;
        localStorage.setItem("token", token);

        setError("");
      } else {
        setError("Invalid username or password");
      }
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (loginResult.isLoading) {
      setIsLoading(true);
    }
    if (loginResult.isError) {
      toast.error("Invalid credentials", {
        position: "top-center",
        autoClose: 1500,
      });
      loginResult.reset();
    }

    if (loginResult.isSuccess) {
      toast.success("Login Successful", {
        position: "top-center",
        autoClose: 1500,
      });
      localStorage.setItem("token", loginResult.data?.token);
      navigate("/dashboard/user");
      loginResult.reset();
    }
  }, [loginResult, navigate]);
  return (
    <Row
      style={{ height: "100vh", overflow: "hidden" }}
      justify={"center"}
      align={"middle"}
    >
      <Col md={9} xl={6}>
        <Row style={{ margin: "2rem 0rem" }}>
          <Title level={3}>Login</Title>
        </Row>

        <Form
          layout={"vertical"}
          size={"large"}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              autoComplete={"off"}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              className="rounded-full px-5"
            />
          </Form.Item>
          <Form.Item
            label="password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon " />}
              type="password"
              placeholder="Password"
              className="rounded-full px-5"
            />
          </Form.Item>

          <Row style={{ margin: "1rem 0rem" }}>
            <Link to="/request-reset" className="login-form-forgot">
              Forgot password?
            </Link>
          </Row>

          {loginResult.status === "pending" && (
            <Row style={{ margin: "1rem" }} justify="center">
              <Spin />
            </Row>
          )}

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="login-form-button bg-secondary text-white hover:bg-primary hover:text-secondary border-none rounded-full"
            >
              Login
            </Button>
            {/* Or <a href="">register now!</a> */}
          </Form.Item>
        </Form>
      </Col>

      <Col
        className={
          "card_col bg-secondary min-h-screen items-center justify-center"
        }
        xs={0}
        lg={10}
        md={10}
        xl={10}
        push={4}
      >
        <div className="flex flex-col items-center justify-center mr-12 px-8 min-h-screen self-center lg:px-0">
          <Logo className=" lg:h-40 lg:w-40 md:h-32 md:w-32" />
          <Title className="text-white text-lg text-center self-center">
            Welcome to Hama Dashboard
          </Title>
        </div>
      </Col>
    </Row>
  );
}
