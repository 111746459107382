import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import axios from "axios";
export const ProtectedRoutes = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [isError, setIsError] = React.useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/request/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        res.data.statusCode === 200 && setIsError(false);
      })
      .catch((err) => setIsError(true));
  }, [location]);

  console.log(isError);

  if (isError) return <Navigate to="/login" replace />;
  return <Outlet />;
};
