import React, { useEffect, useState } from "react";
import {
  useAddTagMutation,
  useDeleteTagMutation,
  useEditTagMutation,
  useGetTagsQuery,
} from "../../../app/services/tags";
import { Pagination } from "../../../components/Pagination";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { message, Modal, Spin } from "antd";
import { AddTagPayload, EditTagPayload, Tag } from "../../../types/tags";
import { TagsCard } from "./TagsCard";
import AddTag from "./AddTag";

export const Tags = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const {
    isFetching: isFetchingTags,
    data: tagsData,
    isLoading: isLoadingGetTags,
    refetch,
  } = useGetTagsQuery({
    page: currentPage,
  });

  const [
    addTag,
    {
      data: addTagData,
      isSuccess: tagAdded,
      isLoading: isLoadingAddTag,
      isError: isErrorAddTag,
      error: addTagErrorData,
    },
  ] = useAddTagMutation();

  const [
    editTag,
    {
      data: editTagData,
      isSuccess: tagEdited,
      isLoading: isLoadingEditTag,
      isError: isErrorEditTag,
      error: editTagErrorData,
    },
  ] = useEditTagMutation();

  const [
    deleteTag,
    {
      isSuccess: tagDeleted,
      isError: isErrorDeleteTag,
      error: deleteTagErrorData,
      isLoading: isLoadingDeleteTag,
    },
  ] = useDeleteTagMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [tagAdded]);

  useEffect(() => {
    if (deleteTagErrorData) return message.error("Failed to delete tag");
    if (tagDeleted) return message.success("Successfully deleted tag");
    if (tagAdded) return message.success("Successfully added tag");
    if (tagEdited) return message.success("Successfully edited tag");
  }, [deleteTagErrorData, tagDeleted, tagAdded, tagEdited]);

  useEffect(() => {
    refetch();
  }, [tagAdded, tagDeleted, tagEdited, currentPage]);

  return (
    <div className="flex flex-col w-full">
      {!isFetchingTags && (
        <div className=" mb-4">
          <button onClick={showModal} className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary hover:!text-black">
            <span className="mr-1 text-white">Add</span>
            <Add className="h-4 w-h-4 fill-white"  />
          </button>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 mb-4 ">
        {isFetchingTags ? (
          <Spin className="loader-svg" />
        ) : (
          <>
            {tagsData?.result !== undefined &&
              tagsData?.result?.map((card: Tag) => {
            

                return (
                  <div key={card._id}>
                    <TagsCard
                      id={card._id}
                      data={card}
                      edit={(tag: EditTagPayload) => editTag(tag)}
                      delete={(card: string) => deleteTag(card)}
                      editSuccess={tagEdited}
                      deleteSuccess={tagDeleted}
                      editError={editTagErrorData}
                      deleteError={deleteTagErrorData}
                      editLoading={isLoadingEditTag}
                      deleteLoading={isLoadingDeleteTag}
                      loading={isLoadingEditTag || isLoadingDeleteTag}
                    />
                  </div>
                );
              })}
          </>
        )}
      </div>

      <Pagination
      
        itemsPerPage={8}
        totalItems={tagsData?.count ? tagsData?.count : 12}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new tag"
          
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddTag
            
            isLoading={isLoadingAddTag}
            addTag={(tag: AddTagPayload) => addTag(tag)}
            isSuccess={tagAdded}
            error={addTagErrorData}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </div>
  );
};
