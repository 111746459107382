import { ArrowLeftOutlined } from "@ant-design/icons";
import i18next from "i18next";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FIcon } from "../../../assets/icons/blog/fb.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/blog/link.svg";
import { ReactComponent as TIcon } from "../../../assets/icons/blog/twitter.svg";
import { markdownComponents } from "../../../components/UI/MarkdownComponents";
import { getDayMonthYear, getShareLinks } from "../../../helpers";

type ArticleContentProps = {
  title: string;
  description: string;
  date: string;
  tags: string;
  minutesToRead: string;
  imgUrl: string;
};

export default function ArticleContent({
  title,
  tags,
  description,
  date,
  minutesToRead,
  imgUrl,
}: ArticleContentProps): JSX.Element {
  const { day, month, year } = getDayMonthYear(date);

  const currentUrl = window.location.href;

  const shareUrls = getShareLinks(currentUrl);
  const navigate = useNavigate();


  return (
    <>
     <div className=" mb-4">
        <button
          onClick={() => navigate("/dashboard/articles")}
          className="flex flex-row justify-center  rounded-lg bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <ArrowLeftOutlined
            className="h-4 w-h-4 !fill-white"
            style={{ color: "white" }}
          />
        </button>
      </div>
    <div style={{ color: "black", background: "black" }}>
     
      <section className="min-h-full w-full bg-black py-10  font-body">
        <div className="pt-40 md:pt-60 bg-black"></div>
        <div className="space-y-20 relative flex flex-col place-items-center  !text-previewSecondary  container min-w-full  min-h-full w-full">
          <div className="space-y-4 w-5/6  md:w-3/6">
            <div className="text-sm flex flex-row place-items-center space-x-5 !text-previewSecondary">
              {tags.split(",").map((tag) => (
                <Tag title={tag} key={tag} />
              ))}
              <span>
                {day} &middot; {month} &middot; {year}
              </span>
              <span className="pa-2">{minutesToRead}</span>
            </div>
            <h1 className="text-4xl font-title !text-previewSecondary">
              {title}
            </h1>
            <ReactMarkdown
              className="text-base"
              components={{
                h1: ({ node, ...props }) => (
                  <h1 className="text-previewSecondary" {...props} />
                ),
                h2: ({ node, ...props }) => (
                  <h1 className="text-previewSecondary" {...props} />
                ),
              }}
            >
              {description.slice(0, 100)}
            </ReactMarkdown>
            <div className="flex flex-row place-items-center space-x-5">
              <a
                href={`${shareUrls.facebook}`}
                target="_blank"
                rel="noreferrer"
              >
                <FIcon title={shareUrls.facebook} />
              </a>
              <a href={`${shareUrls.twitter}`} target="_blank" rel="noreferrer">
                <TIcon title={shareUrls.twitter} />
              </a>
              <a href={shareUrls.link} target="_blank" rel="noreferrer">
                <LinkIcon title={shareUrls.link} />
              </a>
            </div>
          </div>

          <div
            className="!bg-blue-900 w-full !h-96"
            style={{
              backgroundImage: `url(${imgUrl})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>

          <div className="min-h-full min-w-full flex flex-col-reverse md:flex-row justify-between space-x-4 px-4 md:px-7">
            <div className="hidden md:sticky top-1/2 w-4/6 md:w-1/6 h-full md:flex flex-col place-items-center ">
              <div className=" min-w-full  h-full pl-5 flex flex-col-reverse md:flex-col items-start justify-center space-y-7">
                <div className="flex flex-col space-y-4">
                  <a href={shareUrls.facebook} target="_blank" rel="noreferrer">
                    <FIcon title={shareUrls.facebook} />
                  </a>
                  <a href={shareUrls.twitter} target="_blank" rel="noreferrer">
                    <TIcon title={shareUrls.twitter} />
                  </a>
                  <a href={shareUrls.link} target="_blank" rel="noreferrer">
                    <LinkIcon title={shareUrls.link} />
                  </a>
                </div>

                <div className="min-w-full">
                  <span className="md:pt-8">
                    {day} &middot; {month} &middot; {year}
                  </span>
                  <div
                    className="min-w-full rounded mt-4"
                    style={{
                      height: "3px",
                      backgroundSize: "100% 100% !important",
                      backgroundImage:
                        "linear-gradient(to right,white 20%, white 50%)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            {/* for mobile */}
            <div className=" relative mt-20 w-5/6 md:w-1/6 h-full md:hidden flex flex-col place-items-center ">
              <div className="min-w-full  h-full  flex flex-col-reverse md:flex-col items-start justify-center space-y-3">
                <div className="flex flex-row-reverse justify-between  min-w-full  mt-4">
                  <div className="flex flex-row place-items-center space-x-5">
                    <FIcon></FIcon>
                    <TIcon></TIcon>
                    <LinkIcon></LinkIcon>
                  </div>
                </div>

                <div className="min-w-full space-y-3 ">
                  <span className="text-sm pb-4">{date}</span>
                  <div
                    className="min-w-full rounded"
                    style={{
                      height: "3px",
                      backgroundSize: "100% 100% !important",
                      backgroundImage:
                        "linear-gradient(to right,white 20%, white 50%)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <ReactMarkdown
              className="w-5/6 md:w-3/6 h-full font-body text-base flex flex-col space-y-6 "
              components={markdownComponents}
            >
              {description}
            </ReactMarkdown>
            <div className="hidden md:block w-1/6 h-full px-5"></div>
          </div>
        </div>
      </section>
    </div></>
  );
}

const Tag = ({ title }: { title: string }) => {
  return (
    <span
      className={`px-3 py-1 border-2 border-gray-600 ${
        i18next.language !== "en" && "ml-5"
      }`}
    >
      {title}
    </span>
  );
};
