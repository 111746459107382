import { SpecialComponents } from "react-markdown/lib/ast-to-react";
import { NormalComponents } from "react-markdown/lib/complex-types";

export const markdownComponents:
  | Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>
  | undefined = {
  h1: ({ node, ...props }) => (
    <h1 className="text-3xl uppercase text-previewSecondary" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="text-2xl uppercase text-previewSecondary" {...props} />
  ),
  h3: ({ node, ...props }) => (
    <h3 className="text-xl text-previewSecondary" {...props} />
  ),
  h4: ({ node, ...props }) => (
    <h4 className="text-lg text-previewSecondary" {...props} />
  ),
  h5: ({ node, ...props }) => (
    <h5 className="text-base text-previewSecondary" {...props} />
  ),
  h6: ({ node, ...props }) => (
    <h6 className="text-xs text-previewSecondary" {...props} />
  ),
  p: ({ node, ...props }) => <p className="text-base my-4" {...props} />,
  pre: ({ node, ...props }) => (
    <pre className="p-4 bg-slate-900 text-slate-100" {...props} />
  ),
  a: ({ node, ...props }) => (
    <a
      className="text-previewPrimary hover:text-opacity-90 transition border-b border-previewPrimary hover:border-opacity-90"
      target="_blank"
      rel="noreferrer"
      {...props}
    />
  ),
};
