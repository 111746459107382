import { baseApi } from "./baseApi";
import qs from "query-string";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { AddArticlePayload, Article, EditArticlePayload } from "../../types/article";

export const articleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<
      PaginatedResponse<Article>,
      PaginatedPayload<Article>
    >({
      query: (payload) => ({
        url: `article?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),
    getArticle: builder.query<Article, string>({
      query: (id: string) => ({
        url: `article/${id}`,
        method: "GET",
      }),
    }),
    addArticle: builder.mutation<Article, AddArticlePayload>({
      query: (body) => {
        return {
          url: `article`,
          method: "POST",
          body: {
            isFeatured: body.isFeatured,
            title: body.title,
            description: body.description,
            imageUrl: body.imageUrl,
            tags: body.tags,
            duration: body.duration,
            publishedAt: body.publishedAt,
          },
        };
      },
    }),
    editArticle: builder.mutation<Article, EditArticlePayload>({
      query: (body) => ({
        url: `article/${body._id}`,
        method: "PATCH",

        body: {
          isFeatured: body.isFeatured,
          title: body.title,
          description: body.description,
          imageUrl: body.imageUrl,
          tags: body.tags,
          duration: body.duration,
          publishedAt: body.publishedAt,
        },
      }),
    }),
    deleteArticle: builder.mutation<Article, string>({
      query: (id: string) => ({
        url: `article/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddArticleMutation,
  useDeleteArticleMutation,
  useGetArticlesQuery,
  useEditArticleMutation,
  useGetArticleQuery,
} = articleApi;
