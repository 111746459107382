import { baseApi } from "./baseApi";
import {
  User,
  AddUserPayload,
  EditUserPayload,
  ResetPassword,
} from "../../types/user";
import qs from "query-string";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { PaginatedPayload } from "../../types/PaginatedPayload";

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<PaginatedResponse<User>, PaginatedPayload<User>>({
      query: (payload) => ({
        url: `user?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),
    getUser: builder.query<User, string>({
      query: (id: string) => ({
        url: `user/${id}`,
        method: "GET",
      }),
    }),
    addUser: builder.mutation<User, AddUserPayload>({
      query: (body) => {
        return {
          url: `auth/register`,
          method: "POST",
          body,
        };
      },
    }),
    editUser: builder.mutation<User, EditUserPayload>({
      query: (body) => ({
        url: `user/${body._id}`,
        method: "PATCH",

        body: {
          name: body.name,
          email: body.email,
          username: body.username,
          avatar: body.avatar,
          roles: body.roles,
          password: body.password,
        },
      }),
    }),
    deleteUser: builder.mutation<User, string>({
      query: (id: string) => ({
        url: `user/${id}`,
        method: "DELETE",
      }),
    }),
    requestReset: builder.mutation({
      query: (data) => ({
        url: `user/request-reset`,
        method: "POST",
        body: {
          email: data.email,
        },
      }),
    }),
    resetPassword: builder.mutation<
      string,
      ResetPassword & { id: string; token: string }
    >({
      query: (body) => ({
        url: `user/reset-password/${body.id}/${body.token}`,
        method: "POST",
        body: {
          password: body.password,
          confirmPassword: body.confirmPassword,
        },
      }),
    }),
  }),
});

export const {
  useAddUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useEditUserMutation,
  useGetUserQuery,
  useRequestResetMutation,
  useResetPasswordMutation,
} = userApi;
