import React, { useEffect, useState, useCallback } from "react";
import { Pagination } from "../../../components/Pagination";
import { CompanyCard } from "./CompanyCard";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import "../../../app/services/company";
import update from "immutability-helper";
import {
  useAddCompanyMutation,
  useDeleteCompanyMutation,
  useEditCompanyMutation,
  useGetAllCompaniesQuery,
  useUpdateCompanyOrderMutation,
} from "../../../app/services/company";
import { message, Modal } from "antd";
import AddCompany from "./addCompany";
import EditCompany from "./editCompany";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import { useGetAllCompaniesLogosQuery } from "../../../app/services/companyLogo";
import { Company as TCompany } from "../../../types/company";

export const Company = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [companies, setCompanies] = useState<TCompany[]>([]);

  const {
    data: companyLogosData,
    isFetching: isFetchingCompanyLogos,
    refetch: refetchCompanyLogos,
  } = useGetAllCompaniesLogosQuery("");
  const [
    addCompany,
    {
      data: addCompanyData,
      isSuccess: CompanyAdded,
      reset,

      isLoading: isLoadingAddCompany,
      isError: isErrorAddCompany,
      error: addCompanyErrorData,
    },
  ] = useAddCompanyMutation();

  const [
    editCompany,
    {
      data: editCompanyData,
      isSuccess: CompanyEdited,
      isLoading: isLoadingEditCompany,
      isError: isErrorEditCompany,
      error: editCompanyErrorData,
    },
  ] = useEditCompanyMutation();

  const {
    isFetching: isFetchingCompanies,
    data: companiesData,
    refetch,
  } = useGetAllCompaniesQuery({
    page: currentPage,
  });

  useEffect(() => {
    companiesData?.result && setCompanies(companiesData?.result);
  }, [companiesData]);
  const [
    deleteCompany,
    { isSuccess: companyDeleted, error: deleteCompanyErrorData },
  ] = useDeleteCompanyMutation();

  const [updateCompany, result] = useUpdateCompanyOrderMutation();

  // SetCompaniesList(companies);

  function deleteById(id: string, index: number) {
    // companies.result.splice(index, 1);
    deleteCompany(id);
  }

  useEffect(() => {
    refetch();
    reset();
    refetchCompanyLogos();
  }, [companyDeleted, CompanyAdded, CompanyEdited, currentPage]);

  useEffect(() => {
    handleOk();
  }, [CompanyAdded, CompanyEdited]);

  const [modalVisible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (deleteCompanyErrorData)
      return message.error("Failed to delete company");
    if (companyDeleted) return message.success("Successfully deleted company");
    if (CompanyAdded) return message.success("Successfully added company");
    if (CompanyEdited) return message.success("Successfully edited company");
  }, [deleteCompanyErrorData, companyDeleted, CompanyAdded, CompanyEdited]);

  useEffect(() => {
    refetch();
    reset();
  }, [companyDeleted, CompanyAdded, CompanyEdited]);

  const moveCompany = useCallback((dragIndex: number, hoverIndex: number) => {
    setCompanies((prevCompany: TCompany[]) =>
      update(prevCompany, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCompany[dragIndex] as TCompany],
        ],
      })
    );
  }, []);

  useEffect(() => {
    if (companies.length > 0) {
      companies.forEach((company, index) => {
        updateCompany({ id: company._id, order: index + 1 });
      });
    }
  }, [companies]);

  const renderCard = useCallback(
    (item: TCompany, index: number) => {
      return (
        <CompanyCard
          key={item._id}
          avatar={item.imgUrl}
          id={item._id}
          name={item.title[0].value}
          index={index}
          moveCompany={moveCompany}
          deleteTask={() => deleteById(item._id, index)}
          editCompany={editCompany}
          CompanyEdited={CompanyEdited}
          isErrorEditCompany={isErrorEditCompany}
          isLoadingEditCompany={isLoadingEditCompany}
          closeModal={handleOk}
        />
        // <Card
        //   key={card.id}
        //   index={index}
        //   id={card.id}
        //   text={card.text}
        //   moveCompany={moveCompany}
        // />
      );
    },
    [CompanyEdited]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col w-full">
        <div className=" mb-4">
          {/* <h5 className="text-gray-900 text-xl font-medium mr-2">Add a user</h5>
    <Add className="h-6 w-h-6" /> */}
          <button
            onClick={() => showModal()}
            className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
          >
            <span className="mr-1 text-white ">Add</span>
            <Add className="h-4 w-h-4 fill-white " />
          </button>
        </div>

        {modalVisible === true && (
          <Modal
            title="Add a new company"
            visible={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            centered={true}
            footer={false}
            bodyStyle={{
              maxHeight: "800px",
              minWidth: "500px",
              overflowY: "scroll",
            }}
            forceRender={true}
            getContainer={false}
          >
            <AddCompany
              isLoading={isLoadingAddCompany}
              addCompany={addCompany}
              isSuccess={CompanyAdded}
              error={isErrorAddCompany}
              isCanceled={handleCancel}
            ></AddCompany>
          </Modal>
        )}
        <div className="flex flex-wrap  mb-4  ">
          {companies?.map(function (item: any, i: number) {
            return renderCard(item, i);
          })}
        </div>
        <Pagination
          itemsPerPage={10}
          totalItems={10}
          getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
        />
      </div>
    </DndProvider>
  );
};
