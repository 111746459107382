import { baseApi } from "./baseApi";
import {
  Company,
  AddCompanyPayload,
  EditCompanyPayload,
} from "../../types/company";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { PaginatedPayload } from "../../types/PaginatedPayload";

export const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanies: builder.query<
      PaginatedResponse<Company>,
      PaginatedPayload<Company>
    >({
      query: () => {
        return {
          url: `/company?sort=order&limit=40`,
          method: "GET",
        };
      },
    }),
    addCompany: builder.mutation<Company, AddCompanyPayload>({
      query: (body) => {
        return {
          url: `/company`,
          method: "POST",
          body,
        };
      },
    }),
    getCompany: builder.query<Company, string>({
      query: (id: string) => ({
        url: `company/${id}`,
        method: "GET",
      }),
    }),
    addLogo: builder.mutation<Company, AddCompanyPayload>({
      query: (body) => {
        return {
          url: `/logo`,
          method: "POST",
          body,
        };
      },
    }),
    editCompany: builder.mutation<Company, EditCompanyPayload>({
      query: ({
        _id,
        imgUrl,
        modalTitle,
        modalDescription,
        title,
        companyLogo,
        companyLink,
        key,
        subtitle,
        mobileImgUrl,
        showOnSidebar,
        ceoModalImg,
        companiesImg,
        companiesImgMobile,
        seeMoreText,
        visitText,
      }) => {
        return {
          url: `/company/${_id}`,
          method: "PATCH",
          body: {
            title,
            modalTitle,
            modalDescription,
            imgUrl,
            companyLogo,
            companyLink,
            subtitle,
            key,
            mobileImgUrl,
            showOnSidebar,
            ceoModalImg,
            companiesImg,
            companiesImgMobile,
            seeMoreText,
            visitText,
          },
        };
      },
    }),
    deleteCompany: builder.mutation<Company, string>({
      query: (id: string) => ({
        url: `company/${id}`,
        method: "DELETE",
      }),
    }),
    updateCompanyOrder: builder.mutation<
      Company,
      { id: string; order: number }
    >({
      query: ({ id, order }) => ({
        url: `company/${id}`,
        method: "PATCH",
        body: {
          order,
        },
      }),
    }),
  }),
});

export const {
  useAddCompanyMutation,
  useAddLogoMutation,
  useGetCompanyQuery,
  useDeleteCompanyMutation,
  useEditCompanyMutation,
  useGetAllCompaniesQuery,
  useUpdateCompanyOrderMutation,
} = companyApi;
