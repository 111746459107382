import { Articles } from "../routes/dashboard/articles/Article";
import { EditArticle } from "../routes/dashboard/articles/EditArticle";
import { AddArticle } from "../routes/dashboard/articles/AddArticle";
import { Home } from "../routes/dashboard/home/Home";
import { Users } from "../routes/dashboard/users/Users";
import { Settings } from "../routes/dashboard/settings/Settings";
import { Tags } from "../routes/dashboard/tags/Tags";
import { CompanyLogo } from "../routes/dashboard/companyLogo/CompanyLogo";
import { Company } from "../routes/dashboard/company/Company";
import { ArticlePreview } from "../routes/dashboard/articles/ArticlePreview";

export const privateRoutes = [
  {
    path: "",
    Element: Users,
  },
  {
    path: "user",
    Element: Users,
  },
  {
    path: "company",
    Element: Company,
  },
  {
    path: "settings",
    Element: Settings,
  },
  {
    path: "tags",
    Element: Tags,
  },
  {
    path: "articles",
    Element: Articles,
  },
  {
    path: "articles/add",
    Element: AddArticle,
  },
  {
    path: "articles/edit",
    Element: EditArticle,
  },
  {
    path: "articles/preview",
    Element: ArticlePreview,
  },
 
  {
    path: "company-logo",
    Element: CompanyLogo,
  },
 
];
