import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Spin,
  Alert,
  Upload,
  Tabs,
  Select,
  DatePicker,
  Checkbox,
  message,
} from "antd";
import { ArrowLeftOutlined, InboxOutlined } from "@ant-design/icons";
import {
  useEditArticleMutation,
  useGetArticleQuery,
} from "../../../app/services/article";
import MDEditor from "@uiw/react-md-editor";
import { useGetTagsQuery } from "../../../app/services/tags";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

const layout = {
  labelCol: { span: 2 },
};

const token = localStorage.getItem("token");

const { Option } = Select;

export const EditArticle = (): JSX.Element => {
  const navigate = useNavigate();
  const [enDescription, setEnDescription] = useState<any>();
  const [arDescription, setArDescription] = useState<any>();
  const [kuDescription, setKuDescription] = useState<any>();
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState<any>();
  const [previewsImage, setPreviewImage] = useState<{
    url?: string;
    uid?: string;
  }>();

  const {
    isFetching: isFetchingArticle,
    data: articleData,
    isLoading: isLoadingGetArticle,
  } = useGetArticleQuery(`${searchParams.get("id")}`);

  const [isFeatured, setIsFeatured] = useState<boolean>();

  const onCheckboxChange = () => {
    setIsFeatured(!isFeatured);
  };
  const {
    isFetching: isFetchingTags,
    data: tagsData,
    isLoading: isLoadingGetTags,
    refetch,
  } = useGetTagsQuery({});
  console.log("tagsData", tagsData);

  const [
    editArticle,
    {
      data: editArticleData,
      isSuccess: articleEdited,
      isLoading: isLoadingEditArticle,
      isError: isErrorEditArticle,
      error: editArticleErrorData,
    },
  ] = useEditArticleMutation();

  const onFinish = async (values: any) => {
    console.log("values: ", values);
    let imageUrl = values?.imageUrl[0]?.response?.url;
    if (!imageUrl) imageUrl = previewsImage?.url;

    let articleTitle = [];
    const en = values.title.en;
    const ar = values.title.ar
      ? values.title.ar
      : articleData?.title.find((item: any) => item.key === "ar")?.value;
    const ku = values.title.ku
      ? values.title.ku
      : articleData?.title.find((item: any) => item.key === "ku")?.value;
    articleTitle.push(
      {
        key: "en",
        value: en ? en : "-",
      },
      {
        key: "ku",
        value: ku ? ku : "-",
      },
      {
        key: "ar",
        value: ar ? ar : "-",
      }
    );

    let articleDescription = [];
    const enDes = values.description.en;
    const arDes = values.description.ar
      ? values.description.ar
      : articleData?.description.find((item: any) => item.key === "ar")?.value;
    const kuDes = values.description.ku
      ? values.description.ku
      : articleData?.description.find((item: any) => item.key === "ku")?.value;
    articleDescription.push(
      {
        key: "en",
        value: enDes ? enDes : "-",
      },
      {
        key: "ku",
        value: kuDes ? kuDes : "-",
      },
      {
        key: "ar",
        value: arDes ? arDes : "-",
      }
    );

    console.log("values", values);
    // console.log("oldTag", oldTag);

    editArticle({
      title: articleTitle,
      description: articleDescription,
      imageUrl: imageUrl,
      tags: values.tags.map((e: any) => {
        if (e.value) return e.value;
        return e;
      }),
      duration: values.duration,
      publishedAt: values.publishedAt.toISOString(),
      _id: articleData?._id !== undefined ? articleData?._id : "",
      isFeatured,
    });
  };

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  useEffect(() => {
    setIsFeatured(articleData?.isFeatured);
    setInitialValues({
      isFeatured: articleData?.isFeatured,
      duration: articleData?.duration,
      publishedAt: moment(articleData?.publishedAt),
      title: {
        en: articleData?.title.find((item: any) => item.key === "en")?.value,
        ku: articleData?.title.find((item: any) => item.key === "ku")?.value,
        ar: articleData?.title.find((item: any) => item.key === "ar")?.value,
      },
      description: {
        en: articleData?.description.find((item: any) => item.key === "en")
          ?.value,
        ar: articleData?.description.find((item: any) => item.key === "ar")
          ?.value,
        ku: articleData?.description.find((item: any) => item.key === "ku")
          ?.value,
      },
      tags: articleData?.tags.map((tag) => {
        return {
          label: tag.tagName.find((el) => el.key === "en")?.value,
          value: tag._id,
        };
      }),
      imageUrl: [
        {
          url: articleData?.imageUrl,
          uid: "123456789",
          name: articleData?.imageUrl,
        },
      ],
    });
    setPreviewImage({
      url: articleData?.imageUrl,
    });
  }, [articleData]);

  const [form] = Form.useForm();

  useEffect(() => {
    initialValues && form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (articleEdited) {
      message.success("Successfully edited article");
      navigate("/dashboard/articles");
    }
  }, [articleEdited]);

  return (
    <div className="flex flex-col w-full">
      <div className=" mb-4">
        <button
          onClick={() => navigate("/dashboard/articles")}
          className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <ArrowLeftOutlined
            className="h-4 w-h-4 !fill-white"
            style={{ color: "white" }}
          />
        </button>
      </div>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        {...layout}
        form={form}
      >
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: `English`,
              key: "en",
              children: (
                <>
                  <Form.Item
                    name={["title", "en"]}
                    label="Title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input type="text" className="w-80 rounded-2xl text-sm" />
                  </Form.Item>
                  <Form.Item
                    label="Description: "
                    name={["description", "en"]}
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <MDEditor
                      value={enDescription}
                      onChange={setEnDescription}
                      enableScroll={true}
                      className="bg-white "
                      data-color-mode="light"
                    />
                  </Form.Item>
                </>
              ),
            },
            {
              label: `Kurdish`,
              key: "ku",
              children: (
                <>
                  <Form.Item
                    name={["title", "ku"]}
                    label="Title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input className="w-80 rounded-2xl text-sm" type="text" />
                  </Form.Item>
                  <Form.Item
                    label="Description: "
                    name={["description", "ku"]}
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <MDEditor
                      value={kuDescription}
                      onChange={setKuDescription}
                      enableScroll={true}
                      className="bg-white "
                      data-color-mode="light"
                    />
                  </Form.Item>
                </>
              ),
            },
            {
              label: `Arabic`,
              key: "ar",
              children: (
                <>
                  <Form.Item
                    name={["title", "ar"]}
                    label="Title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input className="w-80 rounded-2xl text-sm" type="text" />
                  </Form.Item>
                  <Form.Item
                    label="Description: "
                    name={["description", "ar"]}
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <MDEditor
                      value={arDescription}
                      onChange={setArDescription}
                      enableScroll={true}
                      className="bg-white "
                      data-color-mode="light"
                    />
                  </Form.Item>
                </>
              ),
            },
          ]}
        />

        <Form.Item
          label="Tags"
          rules={[{ required: true, message: "Tag is required" }]}
          name="tags"
          className="rounded-2xl text-sm"
        >
          <Select
            mode="multiple"
            className="w-80 !rounded-2xl text-sm"
            placeholder="Select a tag"
            optionLabelProp="label"
          >
            {tagsData?.result?.map((item) => {
              return (
                <>
                  <Option
                    value={item?._id}
                    label={item?.tagName?.[0]?.value.toString()}
                  >
                    {item?.tagName?.[0]?.value.toString()}
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Duration: "
          name="duration"
          rules={[{ required: true, message: "Duration is required" }]}
        >
          <Input type="text" className="w-80 rounded-2xl text-sm" />
        </Form.Item>
        <Form.Item
          label="Date: "
          name="publishedAt"
          rules={[{ required: true, message: "Date is required" }]}
        >
          <DatePicker className="w-80 rounded-2xl text-sm" />
        </Form.Item>
        <Form.Item
          name="imageUrl"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Image is required" }]}
          label="Article image"
        >
          <Upload.Dragger
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            className="rounded-2xl text-sm"
            defaultFileList={
              articleData?.imageUrl
                ? [
                    {
                      url: articleData?.imageUrl,
                      uid: articleData?._id,
                      name: articleData?.imageUrl.substring(90),
                    },
                  ]
                : undefined
            }
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item label="Is featured" valuePropName="isFeatured">
          <Checkbox checked={isFeatured} onChange={onCheckboxChange} />
        </Form.Item>

        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
            >
              Submit
            </button>
          </div>
          {isLoadingEditArticle && <Spin className="pl-10" />}
        </Form.Item>

        {editArticleErrorData && (
          <Alert message={"something went wrong"} type="error" showIcon />
        )}
        {/* {articleEdited && (
          <Alert
            message={"Article edited successfully"}
            type="success"
            showIcon
          />
        )} */}
      </Form>
    </div>
  );
};
