import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin, Alert, Space } from "antd";
import { EditTagPayload, Tag } from "../../../types/tags";
import { useGetTagQuery } from "../../../app/services/tags";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  editTag: (tag: EditTagPayload) => void;
  isCanceled: () => void;
  id: string;
  // tagData?: Tag;
};

const layout = {
  labelCol: { span: 5 },
  // wrapperCol: { span: 16 },
};

const EditTag = (props: TProps): JSX.Element => {
  const token = localStorage.getItem("token");
  const [initialValues, setInitialValues] = useState<any>();

  const {
    isFetching: isFetchingTag,
    data: tagData,
    refetch,
  } = useGetTagQuery(`${props.id}`);

  const onFinish = async (values: any) => {
    props.editTag({
      tagName: values.tagName.map(
        (el: { _id?: string; value: string; key: string }) => {
          delete el._id;
          return el;
        }
      ),
      _id: props.id,
    });
  };

  useEffect(() => {
    setInitialValues({
      tagName: tagData?.tagName,
    });
  }, [tagData, isFetchingTag]);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="dynamic_form_nest_item"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.List name="tagName">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                // english
                <>
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                    // className="flex flex-col justify-start items-start w-full"
                  >
                    {/* english */}
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[{ required: true, message: "Key is required." }]}
                      // initialValue={"en"}
                    >
                      <Input
                        placeholder="Key"
                        className="rounded-2xl text-sm"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[{ required: true, message: "Value is required" }]}
                    >
                      <Input
                        placeholder="Value"
                        className="rounded-2xl text-sm"
                      />
                    </Form.Item>
{/* 
                    <MinusCircleOutlined onClick={() => remove(name)} /> */}
                  </Space>
                </>
              ))}

              {/* <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item> */}
            </>
          )}
        </Form.List>

        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert message={props.error.data.message} type="error" showIcon />
        )}
        {/* {props.isSuccess && (
          <Alert message={"success"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default EditTag;
