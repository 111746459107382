import React, { useEffect, useState } from "react";
import {
  useAddTagMutation,
  useDeleteTagMutation,
  useEditTagMutation,
  useGetTagsQuery,
} from "../../../app/services/tags";
import { Pagination } from "../../../components/Pagination";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { message, Modal, Spin } from "antd";
import { AddTagPayload, EditTagPayload, Tag } from "../../../types/tags";
import { LogoCard } from "./LogoCard";
import {
  useAddCompanyLogoMutation,
  useDeleteCompanyLogoMutation,
  useEditCompanyLogoMutation,
  useGetCompanyLogosQuery,
} from "../../../app/services/companyLogo";
import {
  AddCompanyLogoPayload,
  EditCompanyLogoPayload,
  Logo,
} from "../../../types/companyLogo";
import AddLogo from "./AddLogo";

export const CompanyLogo = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const {
    isFetching: isFetchingLogos,
    data: logosData,
    isLoading: isLoadingGetLogos,
    refetch,
  } = useGetCompanyLogosQuery({
    page: currentPage,
  });

  const [
    addLogo,
    {
      data: addLogoData,
      isSuccess: logoAdded,
      isLoading: isLoadingAddLogo,
      isError: isErrorAddLogo,
      error: addLogoErrorData,
    },
  ] = useAddCompanyLogoMutation();

  const [
    editLogo,
    {
      data: editLogoData,
      isSuccess: logoEdited,
      isLoading: isLoadingEditLogo,
      isError: isErrorEditLogo,
      error: editLogoErrorData,
    },
  ] = useEditCompanyLogoMutation();

  const [
    deleteLogo,
    {
      isSuccess: logoDeleted,
      isError: isErrorDeleteLogo,
      error: deleteLogoErrorData,
      isLoading: isLoadingDeleteLogo,
    },
  ] = useDeleteCompanyLogoMutation();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(false);
  }, [logoAdded]);

  useEffect(() => {
    if (deleteLogoErrorData) return message.error("Failed to delete logo");
    if (logoDeleted) return message.success("Successfully deleted logo");
    if (logoAdded) return message.success("Successfully added logo");
    if (logoEdited) return message.success("Successfully edited logo");
  }, [deleteLogoErrorData, logoDeleted, logoAdded, logoEdited]);

  useEffect(() => {
    refetch();
  }, [logoAdded, logoDeleted, logoEdited, currentPage]);

  return (
    <div className="flex flex-col w-full ">
      {!isFetchingLogos && (
        <div className=" mb-4">
          <button onClick={showModal} className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary">
            <span className="mr-1 text-white">Add</span>
            <Add className="h-4 w-h-4 fill-white"  />
          </button>
        </div>
      )}

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 mb-4">
        {isFetchingLogos ? (
          <Spin className="loader-svg" />
        ) : (
          <>
            {logosData?.result !== undefined &&
              logosData?.result?.map((card: Logo) => {
                

                return (
                  <div key={card._id}>
                    <LogoCard
                      id={card._id}
                      companyLogo={card.companyLogo}
                      companyName={card.companyName}
                      edit={(logo: EditCompanyLogoPayload) => editLogo(logo)}
                      delete={(logo: string) => deleteLogo(logo)}
                      editSuccess={logoEdited}
                      deleteSuccess={logoDeleted}
                      editError={editLogoErrorData}
                      deleteError={deleteLogoErrorData}
                      editLoading={isLoadingEditLogo}
                      deleteLoading={isLoadingDeleteLogo}
                      loading={isLoadingEditLogo || isLoadingDeleteLogo}
                    />
                  </div>
                );
              })}
          </>
        )}
        {/* <LogoCard /> */}
      </div>

      <Pagination
        itemsPerPage={8}
        totalItems={logosData?.count ? logosData?.count : 12}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />

      {visible === true && (
        <Modal
          title="Add a new Logo"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <AddLogo
            isLoading={isLoadingAddLogo}
            addLogo={(logo: AddCompanyLogoPayload) => addLogo(logo)}
            isSuccess={logoAdded}
            error={addLogoErrorData}
            isCanceled={handleCancel}
          />
        </Modal>
      )}
    </div>
  );
};
