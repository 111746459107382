import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Spin,
  Alert,
  Upload,
  Tabs,
  Select,
  DatePicker,
  Checkbox,
  message,
} from "antd";
import moment from "moment";
import { ArrowLeftOutlined, InboxOutlined } from "@ant-design/icons";
import { useAddArticleMutation } from "../../../app/services/article";
import MDEditor from "@uiw/react-md-editor";
import { useGetTagsQuery } from "../../../app/services/tags";
import { createSearchParams, useNavigate } from "react-router-dom";

const layout = {
  labelCol: { span: 2 },
};

const token = localStorage.getItem("token");

const { Option } = Select;

export const AddArticle = (): JSX.Element => {
  const navigate = useNavigate();

  const [enDescription, setEnDescription] = useState<any>();
  const [arDescription, setArDescription] = useState<any>();
  const [kuDescription, setKuDescription] = useState<any>();
  const [isFeatured, setIsFeatured] = useState<boolean>(false);
  // const [disabled, setDisabled] = useState<boolean>(true);

  const onCheckboxChange = () => {
    setIsFeatured(!isFeatured);
  };

  const {
    isFetching: isFetchingTags,
    data: tagsData,
    isLoading: isLoadingGetTags,
    refetch,
  } = useGetTagsQuery({});

  const [
    addArticle,
    {
      data: addArticleData,
      isSuccess: articleAdded,
      isLoading: isLoadingAddArticle,
      isError: isErrorAddArticle,
      error: addArticleErrorData,
    },
  ] = useAddArticleMutation();

  const onFinish = async (values: any) => {
    const imageUrl = values.imageUrl[0].response.url;

    let articleTitle = [];
    const en = values.title.en;
    const ar = values.title.ar;
    const ku = values.title.ku;
    articleTitle.push(
      {
        key: "en",
        value: en ? en : "-",
      },
      {
        key: "ku",
        value: ku ? ku : "-",
      },
      {
        key: "ar",
        value: ar ? ar : "-",
      }
    );

    let articleDescription = [];
    const enDes = values.description.en;
    const arDes = values.description.ar;
    const kuDes = values.description.ku;
    articleDescription.push(
      {
        key: "en",
        value: enDes ? enDes : "-",
      },
      {
        key: "ku",
        value: kuDes ? kuDes : "-",
      },
      {
        key: "ar",
        value: arDes ? arDes : "-",
      }
    );

    addArticle({
      title: articleTitle,
      description: articleDescription,
      imageUrl: imageUrl,
      tags: values.tags,
      duration: values.duration,
      publishedAt: values.publishedAt.toISOString(),
      isFeatured,
    });
  };

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  useEffect(() => {
    if (articleAdded) {
      message.success("Successfully added article");
      navigate("/dashboard/articles", { state: { articleAdded: true } });
    }
  }, [articleAdded]);

  return (
    <div className="flex flex-col w-full">
      <div className=" mb-4">
        <button
          onClick={() => navigate("/dashboard/articles")}
          className="flex flex-row justify-center rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
        >
          <ArrowLeftOutlined
            className="h-4 w-h-4 !fill-white"
            style={{ color: "white" }}
          />
        </button>
      </div>
      <Form name="dynamic_form_nest_item" onFinish={onFinish} {...layout}>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: `English`,
              key: "en",
              children: (
                <>
                  <Form.Item
                    name={["title", "en"]}
                    label="Title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input type="text" className="w-80 rounded-2xl text-sm" />
                  </Form.Item>
                  <Form.Item
                    label="Description: "
                    name={["description", "en"]}
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <MDEditor
                      value={enDescription}
                      onChange={setEnDescription}
                      enableScroll={true}
                      className="bg-white "
                      data-color-mode="light"
                    />
                  </Form.Item>
                </>
              ),
            },
            {
              label: `Kurdish`,
              key: "ku",
              children: (
                <>
                  <Form.Item
                    name={["title", "ku"]}
                    label="Title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input className="w-80 rounded-2xl text-sm" type="text" />
                  </Form.Item>
                  <Form.Item
                    label="Description: "
                    name={["description", "ku"]}
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <MDEditor
                      value={kuDescription}
                      onChange={setKuDescription}
                      enableScroll={true}
                      className="bg-white "
                      data-color-mode="light"
                    />
                  </Form.Item>
                </>
              ),
            },
            {
              label: `Arabic`,
              key: "ar",
              children: (
                <>
                  <Form.Item
                    name={["title", "ar"]}
                    label="Title"
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input className="w-80 rounded-2xl text-sm" type="text" />
                  </Form.Item>
                  <Form.Item
                    label="Description: "
                    name={["description", "ar"]}
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <MDEditor
                      value={arDescription}
                      onChange={setArDescription}
                      enableScroll={true}
                      className="bg-white "
                      data-color-mode="light"
                    />
                  </Form.Item>
                </>
              ),
            },
          ]}
        />

        <Form.Item
          label="Tags"
          rules={[{ required: true, message: "Tag is required" }]}
          name="tags"
          className="rounded-2xl text-sm"
        >
          <Select
            mode="multiple"
            className="w-80 !rounded-2xl text-sm"
            placeholder="Select a tag"
            optionLabelProp="label"
          >
            {tagsData?.result?.map((item) => {
              return (
                <>
                  <Option
                    value={item?._id}
                    label={item?.tagName?.[0]?.value.toString()}
                  >
                    <div className="demo-option-label-item">
                      <span aria-label={item?.tagName?.[0]?.value.toString()}>
                        {item.tagName[0].value}
                      </span>
                    </div>
                  </Option>
                </>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Duration: "
          name="duration"
          rules={[{ required: true, message: "Duration is required" }]}
        >
          <Input type="text" className="w-80 rounded-2xl text-sm" />
        </Form.Item>
        <Form.Item
          label="Date: "
          name="publishedAt"
          rules={[{ required: true, message: "Date is required" }]}
        >
          <DatePicker className="w-80 rounded-2xl text-sm" />
        </Form.Item>
        <Form.Item
          name="imageUrl"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Image is required" }]}
          label="Article image"
        >
          <Upload.Dragger
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            className="rounded-2xl text-sm"
            // onChange={(info) => {
            //   if (info.file.status !== "uploading") {
            //   }
            //   if (info.file.status === "done") {
            //     setDisabled(false);
            //     // message.success(`file uploaded successfully`);
            //   }
            // }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item label="Is featured" valuePropName="isFeatured">
          <Checkbox checked={isFeatured} onChange={onCheckboxChange} />
        </Form.Item>

        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
              // disabled={disabled}
            >
              Submit
            </button>
          </div>
          {isLoadingAddArticle && <Spin className="pl-10" />}
        </Form.Item>

        {addArticleErrorData && (
          <Alert message={"something went wrong"} type="error" showIcon />
        )}
        {/* {articleAdded && (
          <Alert
            message={"Article added successfully"}
            type="success"
            showIcon
          />
        )} */}
      </Form>
    </div>
  );
};
