import React, { useEffect, useState } from "react";

import { Form, Input, Spin, Alert, Button, Space } from "antd";

import { AddTagPayload } from "../../../types/tags";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  addTag: (tag: AddTagPayload) => void;
  isCanceled: () => void;
};

const layout = {
  labelCol: { span: 5 },
  // wrapperCol: { span: 16 },
};

const AddTag = (props: TProps): JSX.Element => {
  const token = localStorage.getItem("token");

  const onFinish = async (values: any) => {
    props.addTag({
      tagName: values.tagName,
    });
  };

  return (
    <>
      <Form
        
        {...layout}
        name="dynamic_form_nest_item"
        initialValues={{
          tagName: [
            { key: "en" },
            {
              key: "ku",
            },
            {
              key: "ar",
            },
          ],
        }}
        onFinish={onFinish}
      >
        <Form.List name="tagName">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <>
                  {/* english */}
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                    // className="flex flex-col justify-start items-start w-full"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[{ required: true, message: "Key is required." }]}
                    >
                      <Input placeholder="Key" className="rounded-2xl text-sm" disabled/>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[{ required: true, message: "Value is required" }]}
                    >
                      <Input placeholder="Value" className="rounded-2xl text-sm" />
                    </Form.Item>

                    
                  </Space>
                </>
              ))}

              {/* <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item> */}
            </>
          )}
        </Form.List>

        <Form.Item className=" flex flex-col"
        
        >
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="rounded-full mr-2 flex flex-row justify-center  border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="rounded-full flex flex-row justify-center text-white items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert
            message={
              props.error?.data?.errorMessage ==
              "name must match /^[a-zA-Z ]+$/ regular expression"
                ? "Full name can not have numbers"
                : props.error?.data?.errorMessage
            }
            type="error"
            showIcon
          />
        )}
        {/* {props.isSuccess && (
          <Alert message={"success"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default AddTag;
