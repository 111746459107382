import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";

import { Card, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import EditCompany from "./editCompany";
import { useGetCompanyQuery } from "../../../app/services/company";
import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

type TProps = {
  name: string;
  avatar: string;
  index: number;
  moveCompany: (dragIndex: number, hoverIndex: number) => void;
  id: string;
  deleteTask: () => void;
  editCompany: any;
  isLoadingEditCompany: boolean;
  CompanyEdited: boolean;
  isErrorEditCompany: boolean;
  closeModal: () => void;
};

export const CompanyCard = (props: TProps) => {
  const { confirm } = Modal;
  const [modalVisible, setVisible] = useState(false);

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    handleOk();
  }, [props.CompanyEdited]);

  const {
    isFetching: isFetchingCompanies,
    data: company,
    refetch,
  } = useGetCompanyQuery(props.id, {
    refetchOnMountOrArgChange: true,
  });

  const showModal = () => {
    setVisible(true);
    setCounter(counter + 1);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this company?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        props.deleteTask();
      },
      onCancel() {},
    });
  }

  useEffect(() => {
    setVisible(false);
    refetch();
  }, [props.CompanyEdited]);

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "Company",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveCompany(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "Company",
    item: () => {
      return { id: props.id, index: props.index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  const style = { opacity, cursor: "grab" };
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={style}
      data-handler-id={handlerId}
      className="container w-80 mx-2 my-2"
    >
      <Card
        className="w-80 rounded-md shadow-sm"
        cover={
          <img
            alt="example"
            src={props?.avatar}
            className="h-40 object-cover rounded-t-md "
          />
        }
        actions={[
          <DeleteOutlined
            key="delete"
            onClick={() => showDeleteConfirm(props.id)}
            style={{ color: "#dc2626" }}
            className="!fill-red-600 "
          />,
          <EditOutlined
            key="edit"
            className="fill-zinc-500 "
            style={{ color: "#71717a" }}
            onClick={() => showModal()}
          />,
        ]}
      >
        <div className="flex flex-col justify-start items-start w-full overflow-hidden">
          <span className="text-gray-900 text-lg font-medium truncate max-h-8">
            {props.name}
          </span>
        </div>
        {modalVisible === true && (
          <Modal
            title="Edit company"
            visible={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            centered={true}
            bodyStyle={{
              maxHeight: "800px",
              minWidth: "500px",
              overflowY: "scroll",
            }}
            footer={false}
          >
            {company !== undefined && (
              <EditCompany
                key={counter}
                isLoading={props.isLoadingEditCompany}
                editCompany={props.editCompany}
                company={company}
                isSuccess={props.CompanyEdited}
                error={props.isErrorEditCompany}
                cancel={handleCancel}
              />
            )}
          </Modal>
        )}
      </Card>
    </div>
  );
};
