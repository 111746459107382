import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
// import { privateRoutes } from "./app/private-routes";
import { ProtectedRoutes } from "./app/ProtectRoutes";
import Login from "./routes/auth/Login";
import { NotFound } from "./routes/NotFound";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "./routes/dashboard/Container";
import ForgotPassword from "./routes/auth/ForgotPassword";
import ResetPassword from "./routes/auth/ResetPassword";
function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/request-reset" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:id/:token"
            element={<ResetPassword />}
          />

          <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard/*" element={<Container />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
