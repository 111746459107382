import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { queryClient } from "../../../";
import { useGetArticleQuery } from "../../../app/services/article";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArticleContentSkeleton from "../../../components/Skeleton/ArticleContentSkeleton";
import { getValueFromLang } from "../../../helpers";
import ArticleContent from "./ArticleContent";

export const ArticlePreview = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const params = useParams<{ id?: string }>();

  const id = params.id !== undefined ? params.id : "";

  const { t } = useTranslation();

  const {
    isFetching: isFetchingArticle,
    data: article,
    isLoading: isLoading,
  } = useGetArticleQuery(`${searchParams.get("id")}`);


  // const { data: article, isLoading } = useQuery(["article"], () =>
  // useGetArticleQuery({ id: id })
  // );

  // Delete from cache when changing route
  useEffect(() => {
    return () => {
      queryClient.removeQueries(["article"]);
    };
  }, []);

  if (isLoading || article === undefined) {
    return <ArticleContentSkeleton />;
  } else if (article !== undefined) {
    const tags = article?.tags
      .map((tag) => getValueFromLang(tag.tagName))
      .join(",");
    return (
      <ArticleContent
        title={getValueFromLang(article?.title)}
        minutesToRead={article?.duration}
        date={article?.publishedAt}
        tags={tags}
        imgUrl={article?.imageUrl}
        description={getValueFromLang(article?.description)}
      />
    );
  } else {
    return <div>{t("article.notFound")}</div>;
  }
}
