import { baseApi } from "./baseApi";
import {
  Settings,
  AddSettingsPayload,
  EditSettingsPayload,
} from "../../types/settings";

export const settingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => {
        return {
          url: `/setting`,
          method: "GET",
        };
      },
    }),
    addSettings: builder.mutation<Settings, AddSettingsPayload>({
      query: (body) => {
        return {
          url: `/setting`,
          method: "POST",
          body,
        };
      },
    }),
    editSettings: builder.mutation<Settings, EditSettingsPayload>({
      query: ({
        _id,
        address,
        secondAddress,
        thirdAddress,
        email,
        facebookUrl,
        instagramUrl,
        linkedinUrl,
        messengerUrl,
        phoneNumber,
        phoneNumber2,
        phoneNumber3,
        phoneNumber4,
        phoneNumber5,
        twitterUrl,
        youtubeUrl,
        showFb,
        showIg,
        showTwitter,
        showYt,
        showLinkedIn,
        addressLink,
        addressLink2,
        addressLink3,
        copyRightText,
        cooporateNewsText,
        cooporationText,
        cooporateDesc,
        menuButtonText,
        updatesButtonText,
        sidebarBlog,
        scrollToExploreText,
        getInTouch,
        getInTouchDesc,
      }) => {
        return {
          url: `/setting/${_id}`,
          method: "PATCH",
          body: {
            address,
            secondAddress,
            thirdAddress,
            email,
            facebookUrl,
            instagramUrl,
            linkedinUrl,
            messengerUrl,
            phoneNumber,
            phoneNumber2,
            phoneNumber3,
            phoneNumber4,
            phoneNumber5,
            twitterUrl,
            youtubeUrl,
            showFb,
            showIg,
            showTwitter,
            showYt,
            showLinkedIn,
            copyRightText,
            cooporateNewsText,
            cooporationText,
            addressLink,
            addressLink2,
            addressLink3,
            cooporateDesc,
            menuButtonText,
            updatesButtonText,
            sidebarBlog,
            scrollToExploreText,
            getInTouch,
            getInTouchDesc,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useAddSettingsMutation,
  useEditSettingsMutation,
} = settingsApi;
