import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card, message, Modal, Skeleton } from "antd";
import Meta from "antd/lib/card/Meta";
import { useEffect, useState } from "react";
import { useGetCompanyLogoQuery } from "../../../app/services/companyLogo";
import { useGetTagQuery } from "../../../app/services/tags";
import { EditCompanyLogoPayload } from "../../../types/companyLogo";
import { EditTagPayload, Tag } from "../../../types/tags";
import EditLogo from "./EditLogo";

type TProps = {
  id: string;
  loading: boolean;
  companyLogo: string;
  companyName: string;
  delete: (logo: string) => void;
  edit: (logo: EditTagPayload) => void;
  editSuccess: boolean;
  editError: any;
  deleteSuccess: boolean;
  deleteError: any;
  editLoading: boolean;
  deleteLoading: boolean;
};

const { confirm } = Modal;

export const LogoCard = (props: TProps) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(props.loading);

  const {
    isFetching: isFetchingLogo,
    data: logoData,
    refetch,
  } = useGetCompanyLogoQuery(`${props.id}`);

  const onChange = (checked: boolean) => {
    setLoading(!checked);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function showDeleteConfirm(id: string) {
    confirm({
      title: "Are you sure delete this logo?",
      icon: <ExclamationCircleOutlined />,

      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { className: "bg-white border-white" },
      okButtonProps: { className: "bg-white border-red-600" },

      onOk() {
        props.delete(id);
      },
      onCancel() {

      },
    });
  }

  useEffect(() => {
    setVisible(false);
  }, [props.editSuccess]);

  return (
    <div key={props.id} className="">
      <Card
        className="w-60  mt-4 rounded-md"
        bordered={true}
        actions={[
          <DeleteOutlined
            key="delete"
            onClick={() => showDeleteConfirm(props.id)}
            style={{ color: "#dc2626" }}
            className="!fill-red-600 "
          />,
          <EditOutlined
            key="edit"
            className="fill-zinc-500 "
            style={{ color: "#71717a" }}
            onClick={showModal}
          />,
        ]}
      >
        <div className="w-full h-32 mb-4 ">
          <img
            alt="example"
            src={props.companyLogo}
            className="w-full h-32 object-contain p-5 rounded-md bg-secondary"
          />
        </div>
        <span className="text-gray-700 text-sm">Name: {props.companyName}</span>
      </Card>
      {visible === true && (
        <Modal
          title="Edit logo"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          footer={false}
        >
          <EditLogo
            isLoading={props.editLoading}
            editLogo={(logo: EditCompanyLogoPayload) => props.edit(logo)}
            isSuccess={props.editSuccess}
            error={props.editError}
            isCanceled={handleCancel}
            id={props.id}
          />
        </Modal>
      )}
    </div>
  );
};
