import React, { useEffect, useState } from "react";

import {
  Form,
  Input,
  Button,
  Spin,
  Alert,
  Upload,
  message,
  Space,
  Select,
  Checkbox,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Company } from "../../../types/company";
import { useGetAllCompaniesLogosQuery } from "../../../app/services/companyLogo";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: boolean;
  company: Company;
  editCompany: any;
  cancel: () => void;
};

const token = localStorage.getItem("token");

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  // wrapperCol: { span: 16 },
};

const EditCompany = (props: TProps): JSX.Element => {
  const [companyBackground, setCompanyBackground] = useState(
    props.company.imgUrl
  );

  const [ceoModalImg, setCeoModalImg] = useState(props.company.ceoModalImg);

  const [companiesImg, setCompaniesImg] = useState(props.company.companiesImg);
  const [companiesImgMobile, setCompaniesImgMobile] = useState(
    props.company.companiesImgMobile
  );

  const [mobileBackground, setMobileBackground] = useState(
    props.company.mobileImgUrl
  );

  const [companyLogo, setCompanyLogo] = useState(props.company.companyLogo);
  const [showOnSidebar, setShowOnSidebar] = useState<boolean>(false);
  // const [disabled, setDisabled] = useState<boolean>(true);

  const onCheckboxChange = () => {
    setShowOnSidebar(!showOnSidebar);
  };
  const [form] = Form.useForm();

  const {
    data: companyLogos,
    isFetching: isFetchingCompanyLogos,
    refetch: refetchCompanyLogos,
  } = useGetAllCompaniesLogosQuery("");

  const CompanyBackgroundUploadprops: UploadProps = {
    name: "files",
    action: `${process.env.REACT_APP_API_URL}/upload`,
    headers: {
      authorization: `${token}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success("file uploaded successfully");
        setCompanyBackground(info.file.response.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    defaultFileList: [
      {
        uid: "1",
        name: "xxx.png",
        url: props.company.imgUrl,
      },
    ],
  };

  const CompanyMobileImageprops: UploadProps = {
    name: "files",
    action: `${process.env.REACT_APP_API_URL}/upload`,
    headers: {
      authorization: `${token}`,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success("file uploaded successfully");
        setMobileBackground(info.file.response.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    defaultFileList: [
      {
        uid: "2",
        name: "xxx.png",
        url: props.company.mobileImgUrl,
      },
    ],
  };

  useEffect(() => {
    // form.resetFields();
    setShowOnSidebar(props.company.showOnSidebar);
    form.setFieldsValue({
      ...props.company,
      companyLogo: props.company.companyLogo?._id,
      showOnSidebar: props.company.showOnSidebar,
    });
  }, [props.isSuccess, props.company, form]);

  const onFinish = async (values: any) => {
    console.log(values);
    values._id = props.company._id;
    values.imgUrl = companyBackground;
    values.mobileImgUrl = mobileBackground;
    values.ceoModalImg = ceoModalImg;
    values.companiesImg = companiesImg;
    values.companiesImgMobile = companiesImgMobile;
    values.showOnSidebar = showOnSidebar;

    values.modalTitle.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.title.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.modalDescription.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.subtitle.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.visitText.forEach((a: { _id: any }) => {
      delete a._id;
    });
    values.seeMoreText.forEach((a: { _id: any }) => {
      delete a._id;
    });

    props.editCompany(values);
    form.resetFields();
    props.cancel();
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onSearch = (value: string) => {};

  return (
    <>
      <Form
        className="w-full"
        {...layout}
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="title"
          label="Company Name"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="title">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="subtitle"
          label="Subtitle"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="subtitle">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: "Please Enter" }]}
          name="seeMoreText"
          label="Seemore text"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="seeMoreText">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          name="visitText"
          label="Visit text"
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
        >
          <Form.List name="visitText">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item {...restField} name={[name, "key"]}>
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "value"]}>
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item label="Company Link: " name="companyLink">
          <Input className="w-60 rounded-full px-4" name="companyLink" />
        </Form.Item>
        <Form.Item
          label="Key (unique name): "
          name="key"
          rules={[{ required: true, message: "key is required" }]}
        >
          <Input
            placeholder="hama-group"
            className="w-60 rounded-full px-4  "
            name="key"
          />
        </Form.Item>

        <Form.Item
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
          rules={[{ required: true, message: "Please Enter" }]}
          name="modalTitle"
          label="Modal Title"
        >
          <Form.List name="modalTitle">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <Input
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          initialValue={[
            {
              key: "en",
              value: "",
            },
            {
              key: "ar",
              value: "",
            },
            {
              key: "ku",
              value: "",
            },
          ]}
          rules={[{ required: true, message: "Please Enter" }]}
          name="modalDescription"
          label="Modal Description"
        >
          <Form.List name="modalDescription">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="center"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "key"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="border-2 border-slate-300 shadow-sm rounded-full"
                        placeholder="Key"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Value",
                        },
                      ]}
                    >
                      <TextArea
                        className="border-2 w-60 border-slate-300 shadow-sm rounded-full"
                        // placeholder="Value"
                      />
                    </Form.Item>
                    {/* <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  /> */}
                  </Space>
                ))}
                {/* <Form.Item
                  rules={[{ required: true, message: "Please Enter" }]}
                ></Form.Item> */}
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          label="Company Logo: "
          name="companyLogo"
          key={new Date().getTime().toString()}
          rules={[{ required: true, message: "company logo is required" }]}
        >
          <Select
            // onChange={handleChange}
            key={new Date().getTime().toString()}
            showSearch
            optionFilterProp="children"
            onSearch={onSearch}
          >
            {companyLogos?.result?.map((logo: any) => (
              <Option key={logo._id} value={logo._id}>
                {logo.companyName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Background Image: "
          name="imgUrl"
          rules={[{ required: true, message: "Image is required" }]}
        >
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setCompanyBackground(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
            defaultFileList={[
              {
                uid: "2",
                name: "xxx.png",
                url: props.company.imgUrl,
              },
            ]}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Mobile Image: "
          name="mobileImgUrl"
          rules={[{ required: true, message: "Image is required" }]}
        >
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setMobileBackground(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
            defaultFileList={[
              {
                uid: "2",
                name: "xxx.png",
                url: props.company.mobileImgUrl,
              },
            ]}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="Ceo modal image: " name="ceoModalImg">
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            defaultFileList={[
              {
                uid: "2",
                name: "xxx.png",
                url: props.company.ceoModalImg,
              },
            ]}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setCeoModalImg(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="Companies image: " name="companiesImg">
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            defaultFileList={[
              {
                uid: "2",
                name: "xxx.png",
                url: props.company.companiesImg,
              },
            ]}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setCompaniesImg(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Companies mobile: " name="companiesImgMobile">
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            defaultFileList={[
              {
                uid: "2",
                name: "xxx.png",
                url: props.company.companiesImgMobile,
              },
            ]}
            onChange={(info) => {
              if (info.file.status !== "uploading") {
              }
              if (info.file.status === "done") {
                setCompaniesImgMobile(info.file.response.url);
                // message.success(`file uploaded successfully`);
              }
            }}
          >
            <Button icon={<UploadOutlined />} className="rounded-full">
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Show on sidebar" valuePropName="showOnSidebar">
          <Checkbox checked={showOnSidebar} onChange={onCheckboxChange} />
        </Form.Item>

        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.cancel}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert message={"Failed Editing the company"} type="error" showIcon />
        )}
        {/* {props.isSuccess && (
          <Alert message={"success"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default EditCompany;
