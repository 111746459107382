import { baseApi } from "./baseApi";
import qs from "query-string";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import {
  AddCompanyLogoPayload,
  Logo,
  EditCompanyLogoPayload,
} from "../../types/companyLogo";

export const companyLogoApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyLogos: builder.query<
      PaginatedResponse<Logo>,
      PaginatedPayload<Logo>
    >({
      query: (payload) => ({
        url: `company-logo?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),
    getCompanyLogo: builder.query<Logo, string>({
      query: (id: string) => ({
        url: `company-logo/${id}`,
        method: "GET",
      }),
    }),
    addCompanyLogo: builder.mutation<Logo, AddCompanyLogoPayload>({
      query: (body) => {
        return {
          url: `company-logo`,
          method: "POST",
          body: {
            companyLogo: body.companyLogo,
            companyName: body.companyName,
          },
        };
      },
    }),
    editCompanyLogo: builder.mutation<Logo, EditCompanyLogoPayload>({
      query: (body) => ({
        url: `company-logo/${body._id}`,
        method: "PATCH",

        body: {
          companyLogo: body.companyLogo,
          companyName: body.companyName,
        },
      }),
    }),
    getAllCompaniesLogos: builder.query({
      query: () => ({
        url: `company-logo`,
        method: "GET",
      }),
    }),

    deleteCompanyLogo: builder.mutation<Logo, string>({
      query: (id: string) => ({
        url: `company-logo/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllCompaniesLogosQuery,
  useAddCompanyLogoMutation,
  useDeleteCompanyLogoMutation,
  useEditCompanyLogoMutation,
  useGetCompanyLogoQuery,
  useGetCompanyLogosQuery,
} = companyLogoApi;
