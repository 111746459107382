import { baseApi } from "./baseApi";
import qs from "query-string";
import { PaginatedResponse } from "../../types/PaginatedResponse";
import { PaginatedPayload } from "../../types/PaginatedPayload";
import { AddTagPayload, EditTagPayload, Tag } from "../../types/tags";

export const tagsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<PaginatedResponse<Tag>, PaginatedPayload<Tag>>({
      query: (payload) => ({
        url: `tag?${qs.stringify(payload)}`,
        method: "GET",
      }),
    }),
    getTag: builder.query<Tag, string>({
      query: (id: string) => ({
        url: `tag/${id}`,
        method: "GET",
      }),
    }),
    addTag: builder.mutation<Tag, AddTagPayload>({
      query: (body) => {
        return {
          url: `tag`,
          method: "POST",
          body: {
            tagName: body.tagName,
          },
        };
      },
    }),
    editTag: builder.mutation<Tag, EditTagPayload>({
      query: (body) => ({
        url: `tag/${body._id}`,
        method: "PATCH",

        body: {
          tagName: body.tagName,
        },
      }),
    }),
    deleteTag: builder.mutation<Tag, string>({
      query: (id: string) => ({
        url: `tag/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddTagMutation,
  useDeleteTagMutation,
  useGetTagsQuery,
  useEditTagMutation,
  useGetTagQuery,
} = tagsApi;
