import React, { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import {
  useAddArticleMutation,
  useDeleteArticleMutation,
  useEditArticleMutation,
  useGetArticlesQuery,
} from "../../../app/services/article";
import { message, Spin } from "antd";
import { Article } from "../../../types/article";
import { ArticleCard } from "./ArticleCard";

export const Articles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const {
    isFetching: isFetchingArticles,
    data: articlesData,
    isLoading: isLoadingGetArticles,
    refetch,
  } = useGetArticlesQuery({ page: currentPage });

  const [
    editArticle,
    {
      data: editArticleData,
      isLoading: isLoadingEditArticle,
      isError: isErrorEditArticle,
      error: editArticleErrorData,
    },
  ] = useEditArticleMutation();

  const [
    deleteArticle,
    {
      isSuccess: articleDeleted,
      isError: isErrorDeleteArticle,
      error: deleteArticleErrorData,
      isLoading: isLoadingDeleteArticle,
    },
  ] = useDeleteArticleMutation();

  useEffect(() => {
    if (deleteArticleErrorData)
      return message.error("Failed to delete article");
    if (articleDeleted) return message.success("Successfully deleted article");
  }, [deleteArticleErrorData, articleDeleted]);

  useEffect(() => {
    refetch();
  }, [articleDeleted, currentPage]);

  return (
    <div className="flex flex-col w-full">
      {!isFetchingArticles && (
        <div className=" mb-4">
          <button
            onClick={() => navigate("/dashboard/articles/add")}
            className="flex flex-row justify-center  rounded-3xl bg-secondary items-center py-2 px-4 hover:bg-primary"
          >
            <span className="mr-1 text-white">Add</span>
            <Add className="h-4 w-h-4 fill-white" />
          </button>
        </div>
      )}

      <div className="flex flex-wrap  mb-4  ">
        {isFetchingArticles ? (
          <Spin className="loader-svg" />
        ) : (
          <>
            {articlesData?.result !== undefined &&
              articlesData?.result?.map((card: Article) => {
                return (
                  <div key={card._id}>
                    <ArticleCard
                      id={card._id}
                      data={card}
                      // edit={(article: EditArticlePayload) => editArticle(article)}
                      // editSuccess={articleEdited}
                      // editError={editArticleErrorData}
                      // editLoading={isLoadingEditArticle}
                      delete={(card: string) => deleteArticle(card)}
                      deleteSuccess={articleDeleted}
                      deleteError={deleteArticleErrorData}
                      deleteLoading={isLoadingDeleteArticle}
                      loading={isLoadingEditArticle || isLoadingDeleteArticle}
                    />
                  </div>
                );
              })}
          </>
        )}
      </div>
      <Pagination
        itemsPerPage={10}
        totalItems={articlesData?.count ? articlesData?.count : 12}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />
    </div>
  );
};
