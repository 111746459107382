import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { privateRoutes } from "../../app/private-routes";
import { ProtectedRoutes } from "../../app/ProtectRoutes";
import SideBar from "../../components/Sidebar";

export const Container = () => {
  const location = useLocation();
  return (
    <div className="flex flex-row">
      <SideBar currentRoute={location.pathname.substring(1)} />
      <div className="bg-gray-100 flex-1 p-8 flex flex-col overflow-y-auto max-h-screen scroll-smooth">
        <div className="font-light max-full rounded-md p-5 text-xl border-b mb-5">
          {location.pathname.split("/").map((el, index) => {
            const isLast = index === location.pathname.split("/").length - 1;
            return isLast ? (
              <span className="text-secondary">{el}</span>
            ) : (
              <Link className="text-secondary" to={`/${el}`} replace>
                {el}/
              </Link>
            );
          })}
        </div>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            {privateRoutes.map(({ path, Element }, index) => {
              return <Route key={index} path={path} element={<Element />} />;
            })}
          </Route>
        </Routes>
      </div>
    </div>
  );
};
