import React, { useEffect, useState } from "react";

import { Form, Input, Button, Spin, Alert, Upload, message } from "antd";
import PasswordChecklist from "react-password-checklist";
import { AddUserPayload, EditUserPayload, User } from "../../../types/user";
import { useGetUserQuery } from "../../../app/services/User";
import { EditCompanyLogoPayload, Logo } from "../../../types/companyLogo";
import { useGetCompanyLogoQuery } from "../../../app/services/companyLogo";

type TProps = {
  isLoading: boolean;
  isSuccess: boolean;
  error: any;
  editLogo: (logo: EditCompanyLogoPayload) => void;
  isCanceled: () => void;
  id: string;
};

const EditLogo = (props: TProps): JSX.Element => {
  const token = localStorage.getItem("token");
  const [initialValues, setInitialValues] = useState<any>();
  const [previewsImage, setPreviewImage] = useState<{
    url?: string;
    uid?: string;
  }>();

  const {
    isFetching: isFetchingLogo,
    data: logoData,
    refetch,
  } = useGetCompanyLogoQuery(`${props.id}`);

  const onFinish = async (values: any) => {
    let companyLogo = values?.companyLogo[0]?.response?.url;
    if (!companyLogo) companyLogo = previewsImage?.url;

    props.editLogo({
      companyName: values.companyName,
      companyLogo: companyLogo,
      _id: props.id,
    });
  };

  useEffect(() => {
    if (logoData) {
      setInitialValues({
        companyName: logoData?.companyName,
        companyLogo: [
          {
            url: logoData?.companyLogo,
            uid: "12345678",
            name: logoData?.companyLogo,
          },
        ],
      });

      setPreviewImage({
        url: logoData?.companyLogo,
      });
    }
  }, [logoData, logoData, isFetchingLogo]);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const normFile = (e: any) => {
    return e && e.fileList;
  };

  return (
    <>
      <Form
        className="w-full"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Name: "
          name="companyName"
          rules={[{ required: true, message: "Company name is required" }]}
        >
          <Input className="w-60 rounded-2xl text-sm" type="text" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Company logo is required" }]}
          name="companyLogo"
          label="Company logo:  "
          valuePropName="fileList"
          getValueFromEvent={normFile}
          // initialValue={userData?.avatar}
        >
          <Upload
            name="files"
            action={`${process.env.REACT_APP_API_URL}/upload`}
            headers={{ Authorization: `Bearer ${token}` }}
            listType="picture"
            defaultFileList={
              logoData?.companyLogo
                ? [
                    {
                      url: logoData?.companyLogo,
                      uid: logoData._id,
                      name: logoData?.companyLogo.substring(90),
                    },
                  ]
                : undefined
            }
          >
            <Button className="rounded-2xl text-sm">Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item className=" flex flex-col">
          <div className="!flex !flex-row !w-full !justify-end">
            <button
              className="mr-2 flex flex-row justify-center  rounded-full border px-4 py-1 border-secondary items-center bg-white text-secondary hover:bg-secondary hover:text-white"
              onClick={props.isCanceled}
            >
              Cancel
            </button>
            <button
              className="flex flex-row justify-center text-white  rounded-full  items-center px-4 py-1 bg-primary hover:bg-yellow-500"
              type="submit"
            >
              Submit
            </button>
          </div>
          {props.isLoading && <Spin className="pl-10" />}
        </Form.Item>
        {props.error && (
          <Alert message={props.error.data.message} type="error" showIcon />
        )}
        {/* {props.isSuccess && (
          <Alert message={"success"} type="success" showIcon />
        )} */}
      </Form>
    </>
  );
};

export default EditLogo;
