import { Form, Input, Button, Row, Col, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import {
  useRequestResetMutation,
  useResetPasswordMutation,
} from "../../app/services/User";
import { useEffect, useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { ResetPassword } from "../../types/user";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const { Title } = Typography;

const ResetPasswordPage = (): JSX.Element => {
  const params = useParams<{ token: string; id: string }>();
  const [passwordValid, setPasswordValid] = useState("");
  const [confirmPasswordValid, setConfirmPasswordValid] = useState("");

  const [ResetPassword, result] = useResetPasswordMutation();
  const onFinish = (values: ResetPassword) => {
    console.log(values);
    ResetPassword({
      ...values,
      token: params.token ? params.token : "",
      id: params.id ? params.id : "",
    });
  };

  useEffect(() => {
    result.isSuccess &&
      toast.success("Successfully Reset Password", {
        position: "top-center",
        autoClose: 1500,
      });
    if (result.isError) {
      const msg: any = result.error;
      toast.error(msg?.data?.errorMessage?.toString(), {
        position: "top-center",
        autoClose: 1500,
      });
    }
  }, [result]);

  return (
    <Row
      style={{ height: "100vh", overflow: "hidden" }}
      justify={"center"}
      align={"middle"}
      className="px-4 md:px-0 "
    >
      <Col md={9} xl={6}>
        <Row
          justify={"center"}
          style={{
            margin: "2rem 0rem",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Title className="block" level={3}>
            Reset your password{" "}
          </Title>
          <p>enter your new password</p>
        </Row>

        <Form
          layout={"vertical"}
          size={"large"}
          name="normal_login"
          className="login-form flex flex-col items-center justify-center"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="New Password: "
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input.Password
              className="px-5 w-72 rounded-full "
              type="password"
              onChange={(e) => setPasswordValid(e.target.value)}
            />
          </Form.Item>{" "}
          <PasswordChecklist
            rules={["minLength"]}
            minLength={8}
            value={passwordValid}
            style={{ marginBottom: "1rem" }}
          />
          <Form.Item
            label="Confirm Password: "
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match."));
                },
              }),
            ]}
          >
            <Input.Password
              className="px-5 w-72 rounded-full "
              type="password"
            />
          </Form.Item>{" "}
          <Form.Item>
            <Button
              block
              style={{ borderRadius: ".7rem" }}
              type="primary"
              htmlType="submit"
              className="w-72 login-form-button bg-secondary text-white hover:bg-primary hover:text-secondary border-none rounded-full"
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPasswordPage;
